import React from 'react';
import cn from 'classnames';
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { Control, useController } from 'react-hook-form';

export type MyNumberInputProps = {
  className?: string;
  control: Control;
  name: string;
  inputProps?: {
    placeholder?: string;
    variant?: 'outline';
    max?: number;
    min?: number;
    step?: number;
    width?: number;
  };
};

export const MyNumberInput: React.FC<MyNumberInputProps> = props => {
  const { className, control, name, inputProps } = props;

  const ratio = useController({ control, name });

  return (
    <NumberInput
      className={cn('MyNumberInput', className)}
      {...ratio.field}
      size="sm"
      {...inputProps}
    >
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};
