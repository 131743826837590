import React from 'react';
import { SectionDivider } from '@frontend/components/atoms/SectionDivider';
import { SpeciesCardList } from '@frontend/components/organisms/SpeciesCardList';
import { CategoryList } from '@frontend/components/organisms/CropClassList';
import { CROP_CLASS } from '@common/domain/valueObject/species';
import { useFs } from '@frontend/hooks/firestore';
import { MyHead } from '@frontend/components/common/Head';

export type TopProps = {};

export const TopPage: React.FC<TopProps> = () => {
  const useSpeciesResponse = useFs.Species.list(
    Object.values(CROP_CLASS).map(c => c.KEY),
  );

  return (
    <div className="Top">
      <MyHead pageTitle="トップページ" />
      <div className="Section Description">
        趣味の家庭菜園から、農家さんまで！
        <br />
        実際に栽培した品種(タネ・苗)に関する感想や経験を共有できるレビュー投稿サイトです。
      </div>

      <SectionDivider title="人気" type="trend" />
      <SpeciesCardList itemList={useSpeciesResponse.itemList} className="Section" />

      <SectionDivider title="カテゴリ別" />
      <CategoryList className="Section" />
    </div>
  );
};
