import React, { useEffect } from 'react';
import cn from 'classnames';
import { SectionDivider } from '@frontend/components/atoms/SectionDivider';
import { Routing } from '@frontend/settings/routing';
import { ReadMoreWrapper } from '@frontend/components/molecules/ReadMoreWrapper';
import { SeedlingsCardList } from '@frontend/components/organisms/SeedlingsCardList';
import { GoBackLink } from '@frontend/components/atoms/GoBackLink';
import { SearchInput } from '@frontend/components/atoms/SearchInput';
import { LoadingBlockModal } from '@frontend/components/molecules/LoadingBlockModal';
import { useFs } from '@frontend/hooks/firestore';
import { useLocation } from 'react-router-dom';
import { MyHead } from '@frontend/components/common/Head';
import { StorageHooks } from '@frontend/hooks/storage';

export type SeedlingsProps = {
  className?: string;
};

export const SeedlingsPage: React.FC<SeedlingsProps> = props => {
  const { className } = props;

  const { speciesId, searchString } = Routing.Params.Seedlings.useParams();
  const topPageNavigator = Routing.Params.Top.useNavigate();
  const navigator = Routing.Params.Seedlings.useNavigate();

  const { species } = useFs.Species.getById(speciesId);
  const useSeedlingsResponse = useFs.Seedlings.list(speciesId, searchString || undefined);

  const location = useLocation();

  useEffect(() => {
    useSeedlingsResponse.resetPage();
  }, [location.key]);

  const search = (searchString: string) => {
    navigator(speciesId, searchString);
  };

  return (
    <div className={cn('SeedlingsPage', className)}>
      <MyHead
        pageTitle={`「${species?.name}」の品種一覧`}
        ogImage={species?.image?.imageUrl || undefined}
      />
      <div className="Section GoBackLinkContainer">
        <GoBackLink navigator={topPageNavigator} />
      </div>
      <SectionDivider title="品種一覧" />
      <div className="Section">
        <SearchInput defaultValue={searchString || ''} onSearch={search} />
      </div>
      <ReadMoreWrapper className="Section" useReadMoreResponse={useSeedlingsResponse}>
        <SeedlingsCardList
          itemList={useSeedlingsResponse.itemList}
          speciesId={speciesId}
        />
      </ReadMoreWrapper>
      <LoadingBlockModal isLoading={useSeedlingsResponse.isFetching} />
    </div>
  );
};
