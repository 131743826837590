import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Routing as R } from '@frontend/settings/routing';

const routes = Object.values(R.ITEMS);

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
export const RoutingContent: React.FC = () => {
  return (
    <div>
      <ScrollToTop />
      <Routes>
        {routes.map(({ PATH, COMPONENT }) => (
          <Route path={PATH} element={<COMPONENT />} key={PATH} />
        ))}
        <Route path="*" element={<Navigate to={'/error'} />} />
      </Routes>
    </div>
  );
};
