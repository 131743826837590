import { FsAppManager } from '@fs/manager/app';
import { FSAppRepository } from '@frontend/repository/firestore/index';
import { SpeciesId } from '@common/domain/valueObject/species';
import { SeedlingsId } from '@common/domain/entity/seedlings';

export namespace SeedlingsAppRepository {
  export const updateReviewCount = async (
    speciesId: SpeciesId,
    seedlingsId: SeedlingsId,
  ): Promise<number> => {
    // get review count
    const count = await FSAppRepository.count(
      new FsAppManager.Review(speciesId, seedlingsId),
    );
    const seedlingsManager = new FsAppManager.Seedlings(speciesId);
    await FSAppRepository.update(seedlingsManager, seedlingsId, {
      reviewCount: count,
    });
    console.log('update review count of seedlings to ', count);
    return count;
  };
}
