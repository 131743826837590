import { Organization } from '@common/domain/entity/organization';

// prettier-ignore
export const ORGANIZATIONS = {
  ['なし']: new Organization({ name: "なし", displayName: "なし" }),
  ['タキイ種苗']: new Organization({ name: "タキイ種苗", displayName: "タキイ種苗" }),
  ['ときわ研究場']: new Organization({ name: "ときわ研究場", displayName: "ときわ研究場" }),
  ['カネコ種苗']: new Organization({ name: "カネコ種苗", displayName: "カネコ種苗" }),
  ['サカタのタネ']: new Organization({ name: "サカタのタネ", displayName: "サカタのタネ" }),
  ['タカヤマシード']: new Organization({ name: "タカヤマシード", displayName: "タカヤマシード" }),
  ['八江農芸']: new Organization({ name: "八江農芸", displayName: "八江農芸" }),
  ['中原採種場']: new Organization({ name: "中原採種場", displayName: "中原採種場" }),
  ['渡辺採種場']: new Organization({ name: "渡辺採種場", displayName: "渡辺採種場" }),
  ['武蔵野種苗園']: new Organization({ name: "武蔵野種苗園", displayName: "武蔵野種苗園" }),
  ['松永種苗']: new Organization({ name: "松永種苗", displayName: "松永種苗" }),
  ['朝日アグリア']: new Organization({ name: "朝日アグリア", displayName: "朝日アグリア" }),
  ['日本農林社']: new Organization({ name: "日本農林社", displayName: "日本農林社" }),
  ['渡辺農事']: new Organization({ name: "渡辺農事", displayName: "渡辺農事" }),
  ['秋田県農林水産部農林政策課研究推進班']: new Organization({ name: "秋田県農林水産部農林政策課研究推進班", displayName: "秋田県農林水産部農林政策課研究推進班" }),
  ['ヴィルモランみかど']: new Organization({ name: "ヴィルモランみかど", displayName: "ヴィルモランみかど" }),
  ['神奈川県']: new Organization({ name: "神奈川県", displayName: "神奈川県" }),
  ['神田育種農場']: new Organization({ name: "神田育種農場", displayName: "神田育種農場" }),
  ['アカヲ種苗']: new Organization({ name: "アカヲ種苗", displayName: "アカヲ種苗" }),
  ['久留米原種育成会']: new Organization({ name: "久留米原種育成会", displayName: "久留米原種育成会" }),
  ['愛三種苗']: new Organization({ name: "愛三種苗", displayName: "愛三種苗" }),
  ['山陽種苗']: new Organization({ name: "山陽種苗", displayName: "山陽種苗" }),
  ['トーホク']: new Organization({ name: "トーホク", displayName: "トーホク" }),
  ['ナント種苗']: new Organization({ name: "ナント種苗", displayName: "ナント種苗" }),
  ['丸種']: new Organization({ name: "丸種", displayName: "丸種" }),
  ['農研機構']: new Organization({ name: "農研機構", displayName: "農研機構" }),
  ['萩原農場']: new Organization({ name: "萩原農場", displayName: "萩原農場" }),
  ['奈良育種研究農場']: new Organization({ name: "奈良育種研究農場", displayName: "奈良育種研究農場" }),
  ['横浜植木']: new Organization({ name: "横浜植木", displayName: "横浜植木" }),
  ['福井市園芸センター']: new Organization({ name: "福井市園芸センター", displayName: "福井市園芸センター" }),
  ['鳥取県農林水産部農林水産政策課']: new Organization({ name: "鳥取県農林水産部農林水産政策課", displayName: "鳥取県農林水産部農林水産政策課" }),
  ['福岡県農林水産部農林水産政策課研究調整係']: new Organization({ name: "福岡県農林水産部農林水産政策課研究調整係", displayName: "鳥取県農林水産部農林水産政策課" }),
  ['多久市農林課農政係']: new Organization({ name: "多久市農林課農政係", displayName: "多久市農林課農政係" }),
  ['福島県']: new Organization({ name: "福島県", displayName: "福島県" }),
  ['長野県農政部農業技術課、長野県農業試験場知的財産管理部']: new Organization({ name: "長野県農政部農業技術課、長野県農業試験場知的財産管理部", displayName: "長野県農政部農業技術課、長野県農業試験場知的財産管理部" }),
  ['トキタ種苗']: new Organization({ name: "トキタ種苗", displayName: "トキタ種苗" }),
  ['フジイシード']: new Organization({ name: "フジイシード", displayName: "フジイシード" }),
  ['福井県農林水産部園芸振興課']: new Organization({ name: "福井県農林水産部園芸振興課", displayName: "福井県農林水産部園芸振興課" }),
  ['地方独立行政法人青森県産業技術センター農林総合研究所']: new Organization({ name: "地方独立行政法人青森県産業技術センター農林総合研究所", displayName: "地方独立行政法人青森県産業技術センター農林総合研究所" }),
  ['佐藤政行種苗']: new Organization({ name: "佐藤政行種苗", displayName: "佐藤政行種苗" }),
  ['公益財団']: new Organization({ name: "公益財団", displayName: "公益財団" }),
  ['埼玉原種育成会']: new Organization({ name: "埼玉原種育成会", displayName: "埼玉原種育成会" }),
  ['トヨタネ']: new Organization({ name: "トヨタネ", displayName: "トヨタネ" }),
  ['福井シード']: new Organization({ name: "福井シード", displayName: "福井シード" }),
  ['増田採種場']: new Organization({ name: "増田採種場", displayName: "増田採種場" }),
  ['太田種苗']: new Organization({ name: "太田種苗", displayName: "太田種苗" }),
  ['FSブルーム']: new Organization({ name: "FSブルーム", displayName: "FSブルーム" }),
  ['新潟県農林水産部農業総務課政策室']: new Organization({ name: "新潟県農林水産部農業総務課政策室", displayName: "新潟県農林水産部農業総務課政策室" }),
  ['徳島県']: new Organization({ name: "徳島県", displayName: "徳島県" }),
  ['岩倉種苗店']: new Organization({ name: "岩倉種苗店", displayName: "岩倉種苗店" }),
  ['ミヤサカのタネ']: new Organization({ name: "ミヤサカのタネ", displayName: "ミヤサカのタネ" }),
  ['三島食品']: new Organization({ name: "三島食品", displayName: "三島食品" }),
  ['和歌山県研究推進室']: new Organization({ name: "和歌山県研究推進室", displayName: "和歌山県研究推進室" }),
  ['東京都産業労働局農林水産部調整課']: new Organization({ name: "東京都産業労働局農林水産部調整課", displayName: "東京都産業労働局農林水産部調整課" }),
  ['兵庫県立農林水産技術総合センター']: new Organization({ name: "兵庫県立農林水産技術総合センター", displayName: "兵庫県立農林水産技術総合センター" }),
  ['日恵野']: new Organization({ name: "日恵野", displayName: "日恵野" }),
  ['みかど協和']: new Organization({ name: "みかど協和", displayName: "みかど協和" }),
  ['静岡県経済産業部農業局農業戦略課']: new Organization({ name: "静岡県経済産業部農業局農業戦略課", displayName: "静岡県経済産業部農業局農業戦略課" }),
  ['奈良県食と農の振興部']: new Organization({ name: "奈良県食と農の振興部", displayName: "奈良県食と農の振興部" }),
  ['愛知県農業水産局農政部農業経営課']: new Organization({ name: "愛知県農業水産局農政部農業経営課", displayName: "愛知県農業水産局農政部農業経営課" }),
  ['山口県農林水産部農林水産政策課']: new Organization({ name: "山口県農林水産部農林水産政策課", displayName: "山口県農林水産部農林水産政策課" }),
  ['豊橋温室園芸農業協同組合']: new Organization({ name: "豊橋温室園芸農業協同組合", displayName: "豊橋温室園芸農業協同組合" }),
  ['福井県農林水産部園芸振興課\n福井県農業試験場']: new Organization({ name: "福井県農林水産部園芸振興課\n福井県農業試験場", displayName: "福井県農林水産部園芸振興課\n福井県農業試験場" }),
  ['国立大学法人信州大学']: new Organization({ name: "国立大学法人信州大学", displayName: "国立大学法人信州大学" }),
  ['床枝']: new Organization({ name: "床枝", displayName: "床枝" }),
  ['有限会社マルウわさび郷']: new Organization({ name: "有限会社マルウわさび郷", displayName: "有限会社マルウわさび郷" }),
  ['宮崎県農政水産部農業普及技術課']: new Organization({ name: "宮崎県農政水産部農業普及技術課", displayName: "宮崎県農政水産部農業普及技術課" }),
  ['宮本']: new Organization({ name: "宮本", displayName: "宮本" }),
  ['ペッパー・レボリューション・カンパニー合同会社']: new Organization({ name: "ペッパー・レボリューション・カンパニー合同会社", displayName: "ペッパー・レボリューション・カンパニー合同会社" }),
  ['大野光男']: new Organization({ name: "大野光男", displayName: "大野光男" }),
  ['宮内']: new Organization({ name: "宮内", displayName: "宮内" }),
  ['栃木県']: new Organization({ name: "栃木県", displayName: "栃木県" }),
  ['香川県']: new Organization({ name: "香川県", displayName: "香川県" }),
  ['岡山県']: new Organization({ name: "岡山県", displayName: "岡山県" }),
  ['岐阜県農政部農政課農業研究推進係']: new Organization({ name: "岐阜県農政部農政課農業研究推進係", displayName: "岐阜県農政部農政課農業研究推進係" }),
  ['鹿児島県農政部経営技術課技術環境係']: new Organization({ name: "鹿児島県農政部経営技術課技術環境係", displayName: "鹿児島県農政部経営技術課技術環境係" }),
  ['水野']: new Organization({ name: "水野", displayName: "水野" }),
  ['公益財団園芸植物育種研究所']: new Organization({ name: "公益財団園芸植物育種研究所", displayName: "公益財団園芸植物育種研究所" }),
  ['道総研']: new Organization({ name: "道総研", displayName: "道総研" }),
  ['大分県農林水産部地域農業振興課普及・研究班']: new Organization({ name: "大分県農林水産部地域農業振興課普及・研究班", displayName: "大分県農林水産部地域農業振興課普及・研究班" }),
  ['熊本県農林水産部生産経営局農業技術課']: new Organization({ name: "熊本県農林水産部生産経営局農業技術課", displayName: "熊本県農林水産部生産経営局農業技術課" }),
  ['東京都農林総合研究センター']: new Organization({ name: "東京都農林総合研究センター", displayName: "東京都農林総合研究センター" }),
  ['佐賀県農林水産部農政企画課']: new Organization({ name: "佐賀県農林水産部農政企画課", displayName: "佐賀県農林水産部農政企画課" }),
  ['日光種苗']: new Organization({ name: "日光種苗", displayName: "日光種苗" }),
  ['有限会社角田ナーセリー']: new Organization({ name: "有限会社角田ナーセリー", displayName: "有限会社角田ナーセリー" }),
  ['山形県農林水産部農業技術環境課']: new Organization({ name: "山形県農林水産部農業技術環境課", displayName: "山形県農林水産部農業技術環境課" }),
  ['ホクサン']: new Organization({ name: "ホクサン", displayName: "ホクサン" }),
  ['京都大学']: new Organization({ name: "京都大学", displayName: "京都大学" }),
  ['群馬県農政課']: new Organization({ name: "群馬県農政課", displayName: "群馬県農政課" }),
  ['愛媛県農林水産部農業振興局農産園芸課研究企画係']: new Organization({ name: "愛媛県農林水産部農業振興局農産園芸課研究企画係", displayName: "愛媛県農林水産部農業振興局農産園芸課研究企画係" }),
  ['有限会社八幡屋礒五郎']: new Organization({ name: "有限会社八幡屋礒五郎", displayName: "有限会社八幡屋礒五郎" }),
  ['三重県農業研究所']: new Organization({ name: "三重県農業研究所", displayName: "三重県農業研究所" }),
  ['雪印種苗']: new Organization({ name: "雪印種苗", displayName: "雪印種苗" }),
  ['岡村農園']: new Organization({ name: "岡村農園", displayName: "岡村農園" }),
  ['千葉県農林水産部担い手支援課技術振興室']: new Organization({ name: "千葉県農林水産部担い手支援課技術振興室", displayName: "千葉県農林水産部担い手支援課技術振興室" }),
  ['広島県立総合技術研究所企画部']: new Organization({ name: "広島県立総合技術研究所企画部", displayName: "広島県立総合技術研究所企画部" }),
  ['ゆうき青森農業協同組合']: new Organization({ name: "ゆうき青森農業協同組合", displayName: "ゆうき青森農業協同組合" }),
  ['アグリス']: new Organization({ name: "アグリス", displayName: "アグリス" }),
  ['ミヨシグループ']: new Organization({ name: "ミヨシグループ", displayName: "ミヨシグループ" }),
  ['富山県']: new Organization({ name: "富山県", displayName: "富山県" }),
  ['ハクサン']: new Organization({ name: "ハクサン", displayName: "ハクサン" }),
  ['国立研究開発法人国際農林水産業研究センター']: new Organization({ name: "国立研究開発法人国際農林水産業研究センター", displayName: "国立研究開発法人国際農林水産業研究センター" }),
  ['岩手県環境保健研究センター']: new Organization({ name: "岩手県環境保健研究センター", displayName: "岩手県環境保健研究センター" }),
  ['石川県農林水産部生産流通課']: new Organization({ name: "石川県農林水産部生産流通課", displayName: "石川県農林水産部生産流通課" }),
  ['山梨県農政部農業技術課']: new Organization({ name: "山梨県農政部農業技術課", displayName: "山梨県農政部農業技術課" }),
  ['三枝園']: new Organization({ name: "三枝園", displayName: "三枝園" }),
  ['新居バイオ花き研究所']: new Organization({ name: "新居バイオ花き研究所", displayName: "新居バイオ花き研究所" }),
  ['アグリプラネット']: new Organization({ name: "アグリプラネット", displayName: "アグリプラネット" }),
  ['岩手県農林水産部農業普及技術課']: new Organization({ name: "岩手県農林水産部農業普及技術課", displayName: "岩手県農林水産部農業普及技術課" }),
  ['地方独立行政法人大阪府立環境農林水産総合研究所企画部研究支援グループ']: new Organization({ name: "地方独立行政法人大阪府立環境農林水産総合研究所企画部研究支援グループ", displayName: "地方独立行政法人大阪府立環境農林水産総合研究所企画部研究支援グループ" }),
  ['農事組合法人三河温室園芸組合']: new Organization({ name: "農事組合法人三河温室園芸組合", displayName: "農事組合法人三河温室園芸組合" }),
  ['ニチレイフーズ']: new Organization({ name: "ニチレイフーズ", displayName: "ニチレイフーズ" }),
  ['滋賀県農政水産部農政課企画・財産係']: new Organization({ name: "滋賀県農政水産部農政課企画・財産係", displayName: "滋賀県農政水産部農政課企画・財産係" }),
  ['手島']: new Organization({ name: "手島", displayName: "手島" }),
  ['井上石灰工業']: new Organization({ name: "井上石灰工業", displayName: "井上石灰工業" }),
  ['ブロリード']: new Organization({ name: "ブロリード", displayName: "ブロリード" }),
  ['国立研究開発法人医薬基盤']: new Organization({ name: "国立研究開発法人医薬基盤", displayName: "国立研究開発法人医薬基盤" }),
  ['香川大学']: new Organization({ name: "香川大学", displayName: "香川大学" }),
  ['国立研究開発法人医薬基盤・健康・栄養研究所']: new Organization({ name: "国立研究開発法人医薬基盤・健康・栄養研究所", displayName: "国立研究開発法人医薬基盤・健康・栄養研究所" }),
  ['奈良県食と農の振興部農業水産振興課果樹農産係']: new Organization({ name: "奈良県食と農の振興部農業水産振興課果樹農産係", displayName: "奈良県食と農の振興部農業水産振興課果樹農産係" }),
  ['池田町ブドウ']: new Organization({ name: "池田町ブドウ", displayName: "池田町ブドウ" }),
  ['植原葡萄研究所']: new Organization({ name: "植原葡萄研究所", displayName: "植原葡萄研究所" }),
  ['近畿大学']: new Organization({ name: "近畿大学", displayName: "近畿大学" }),
  ['飯塚果樹園']: new Organization({ name: "飯塚果樹園", displayName: "飯塚果樹園" }),
  ['学校法人東京農業大学']: new Organization({ name: "学校法人東京農業大学", displayName: "学校法人東京農業大学" }),
  ['ブルーベリー安曇野']: new Organization({ name: "ブルーベリー安曇野", displayName: "ブルーベリー安曇野" }),
  ['南アルプス市農業協同組合']: new Organization({ name: "南アルプス市農業協同組合", displayName: "南アルプス市農業協同組合" }),
  ['長崎県農政課']: new Organization({ name: "長崎県農政課", displayName: "長崎県農政課" }),
  ['信州大学学術研究']: new Organization({ name: "信州大学学術研究", displayName: "信州大学学術研究" }),
  ['JA鹿児島いずみ']: new Organization({ name: "JA鹿児島いずみ", displayName: "JA鹿児島いずみ" }),
  ['弘果']: new Organization({ name: "弘果", displayName: "弘果" }),
  ['キッコーマン']: new Organization({ name: "キッコーマン", displayName: "キッコーマン" }),
  ['青森県産業技術センター']: new Organization({ name: "青森県産業技術センター", displayName: "青森県産業技術センター" }),
  ['みなべ町役場うめ課']: new Organization({ name: "みなべ町役場うめ課", displayName: "みなべ町役場うめ課" }),
  ['イシドウ']: new Organization({ name: "イシドウ", displayName: "イシドウ" }),
  ['澁谷農園']: new Organization({ name: "澁谷農園", displayName: "澁谷農園" }),
  ['鹿児島県']: new Organization({ name: "鹿児島県", displayName: "鹿児島県" }),
  ['池田町ブドウ･ブドウ酒研究所']: new Organization({ name: "池田町ブドウ･ブドウ酒研究所", displayName: "池田町ブドウ･ブドウ酒研究所" }),
  ['信州大学学術研究・産学官連携推進機構']: new Organization({ name: "信州大学学術研究・産学官連携推進機構", displayName: "信州大学学術研究・産学官連携推進機構" }),
  ['サントリー']: new Organization({ name: "サントリー", displayName: "サントリー" }),
  ['パナプラス']: new Organization({ name: "パナプラス", displayName: "パナプラス" }),
} as const;

export const getOrganizationByName = (name?: string) => {
  if (!name) return;
  // @ts-ignore
  return ORGANIZATIONS[name] as Organization;
};
