import { useFs_Seedlings_list } from '@frontend/hooks/firestore/seedlings/list';
import {
  useFs_Seedlings_ById,
  UseFs_Seedlings_ByIdRes,
} from '@frontend/hooks/firestore/seedlings/byId';

export namespace useFs_Seedlings {
  export const list = useFs_Seedlings_list;

  export type byIdRes = UseFs_Seedlings_ByIdRes;
  export const byId = useFs_Seedlings_ById;
}
