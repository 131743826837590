import React from 'react';
import cn from 'classnames';
import { Modal, ModalBody, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react';

export type LoadingBlockModalProps = {
  className?: string;
  isLoading: boolean;
};

export const LoadingBlockModal: React.FC<LoadingBlockModalProps> = props => {
  const { className, isLoading } = props;

  return (
    <Modal
      isOpen={isLoading}
      onClose={() => {
        /* do nothing */
      }}
      size="xs"
    >
      <ModalOverlay />
      <ModalContent className={cn('LoadingBlockModal', className)}>
        <ModalBody className="ModalBody">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
          読込中 ...
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
