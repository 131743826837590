import React from 'react';
import cn from 'classnames';
import { Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MdAccountCircle } from 'react-icons/md';

export type HeaderMenuProps = {
  className?: string;
  onSignOut: () => void;
  toSignInPage: () => void;
  toUserSettingsPage: () => void;
  toAboutPage: () => void;
  userName: string | undefined;
};

function renderMenuButton(userName: string | undefined) {
  if (!userName) {
    return <MenuButton>未ログイン</MenuButton>;
  }
  return (
    <MenuButton>
      <Icon as={MdAccountCircle} className="Icon">
        アイコン
      </Icon>
      {userName}
    </MenuButton>
  );
}

export const HeaderMenu: React.FC<HeaderMenuProps> = props => {
  const {
    className,
    toSignInPage,
    onSignOut,
    toUserSettingsPage,
    toAboutPage,
    userName,
  } = props;

  const items = userName
    ? [
        <MenuItem key="to-user-settings-page" onClick={toUserSettingsPage}>
          ユーザ設定
        </MenuItem>,
        <MenuItem key="about" onClick={toAboutPage}>
          このページについて
        </MenuItem>,
        <MenuItem key="sign-out" onClick={onSignOut}>
          ログアウト
        </MenuItem>,
      ]
    : [
        <MenuItem key="about" onClick={toAboutPage}>
          このページについて
        </MenuItem>,
        <MenuItem key="to-sign-in-page" onClick={toSignInPage}>
          ログイン
        </MenuItem>,
      ];

  return (
    <Menu>
      <div className="HeaderMenu MenuButtonContainer">{renderMenuButton(userName)}</div>

      <MenuList className={cn('HeaderMenu', className)}>{items}</MenuList>
    </Menu>
  );
};
