import { useMutation, useQuery } from '@tanstack/react-query';
import { StorageRepository } from '@frontend/repository/storage';
import { UserId } from '@common/domain/entity/user';
import { Review } from '@common/domain/entity/review';

export namespace StorageHooks {
  export namespace Review {
    type StorageDirPathParams = {
      userId: UserId;
      createdAt: string;
    };
    export const storageDirPath = (params: StorageDirPathParams) => {
      const { userId, createdAt } = params;
      return `review-images/${userId}/${createdAt}`;
    };
    type StorageFilePathParams = StorageDirPathParams & { index: number };
    export const storageFilePath = (params: StorageFilePathParams) => {
      const { index, ...rest } = params;
      return `${storageDirPath(rest)}/${index}`;
    };

    type UseUploaderParams = {
      file: File;
      index: number;
      userId: UserId;
      createdAt: string;
    };
    export const useUploader = () => {
      const { mutate } = useMutation(async (params: UseUploaderParams) => {
        const { file, ...rest } = params;
        const filePath = storageFilePath(rest);
        await StorageRepository.uploadImage(filePath, file).catch(e => {
          console.error(e);
          throw e;
        });
        return filePath;
      }, {});
      return mutate;
    };
    type UseDeleteParams = {
      userId: UserId;
      createdAt: string;
    };

    // delete all images of the review
    export const useDelete = () => {
      const { mutate } = useMutation(async (params: UseDeleteParams) => {
        const filePath = storageDirPath(params);
        await StorageRepository.deleteItems(filePath).catch(e => {
          console.error(e);
          throw e;
        });
        return filePath;
      }, {});
      return mutate;
    };

    export const useImages = (review: Review): { imageUrls: string[] } => {
      const dirPath = storageDirPath({
        userId: review.userId,
        createdAt: review.createdAt,
      });
      const { data } = useQuery(
        [review.id],
        async () => {
          return await StorageRepository.getDownloadUrls(dirPath);
        },
        {
          retry: false,
          cacheTime: 0,
          refetchOnMount: false,
          refetchOnWindowFocus: false,
        },
      );

      return { imageUrls: data || [] };
    };
  }
}
