import React from 'react';
import cn from 'classnames';
import { Input } from '@chakra-ui/react';
import { Path, UseControllerReturn } from 'react-hook-form';

export type MyInputProps<
  Inputs extends Record<string, any>,
  Name extends Path<Inputs>,
> = {
  controller: UseControllerReturn<Inputs, Name>;
  className?: string;
  readOnly?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  type?: 'text' | 'number' | 'password' | 'email';
  disabled?: boolean;
  variant?: 'outline';
  size?: 'sm';
};

export function MyInput<Inputs extends Record<string, any>, Name extends Path<Inputs>>(
  props: MyInputProps<Inputs, Name>,
) {
  const { className, controller, ...rest } = props;
  return <Input className={cn('MyInput', className)} {...controller.field} {...rest} />;
}
