import { User as DomainUser } from '@common/domain/entity/user';
import {
  Seedlings as DomainSeedlings,
  SeedlingsId,
} from '@common/domain/entity/seedlings';
import { Species as DomainSpecies, SpeciesId } from '@common/domain/valueObject/species';
import { Entity } from '@common/domain/entity/common';
import fs from 'firebase/firestore';
import { Review as DomainReview } from '@common/domain/entity/review';

const getSubCollectionPath = <P extends Entity>(
  parentPath: FsManager<P, Converter<P>>['path'],
  parentId: P['id'],
  childrenEntityName: string,
) => {
  return [parentPath, parentId, childrenEntityName].join('/');
};

type Converter<T> =
  | fs.FirestoreDataConverter<T>
  | FirebaseFirestore.FirestoreDataConverter<T>;
export type FsManager<T extends Entity, C extends Converter<T>> = {
  path: string;
  converter: C;
};
export namespace FsManager {
  export class User<C extends Converter<DomainUser>> implements FsManager<DomainUser, C> {
    static _name = 'user';
    static createPath = () => User._name;
    path: string;
    converter: C;

    constructor(converter: C) {
      this.converter = converter;
      this.path = User.createPath();
    }
  }

  export class Species<C extends Converter<DomainSpecies>>
    implements FsManager<DomainSpecies, C>
  {
    static _name = 'species';
    static createPath = () => Species._name;
    path: string;
    converter: C;

    constructor(converter: C) {
      this.converter = converter;
      this.path = Species.createPath();
    }
  }

  export class Seedlings<C extends Converter<DomainSeedlings>>
    implements FsManager<DomainSeedlings, C>
  {
    static _name = 'seedlings';
    static createPath = (speciesId: SpeciesId) => {
      const parentPath = Species.createPath();
      return getSubCollectionPath(parentPath, speciesId, Seedlings._name);
    };
    path: string;
    converter: C;

    constructor(converter: C, speciesId: SpeciesId) {
      this.converter = converter;
      this.path = Seedlings.createPath(speciesId);
    }
  }

  export class Review<C extends Converter<DomainReview>>
    implements FsManager<DomainReview, C>
  {
    static _name = 'review';
    static createPath = (speciesId: SpeciesId, seedlingsId: SeedlingsId) => {
      const parentPath = Seedlings.createPath(speciesId);
      return getSubCollectionPath(parentPath, seedlingsId, Review._name);
    };
    path: string;
    converter: C;

    constructor(converter: C, speciesId: SpeciesId, seedlingsId: SeedlingsId) {
      this.converter = converter;
      this.path = Review.createPath(speciesId, seedlingsId);
    }
  }
}
