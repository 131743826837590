import { useReadMore, UseReadMoreResponse } from '@frontend/hooks/firestore/readMore';
import { FsAppManager } from '@fs/manager/app';
import { FSAppRepository } from '@frontend/repository/firestore';
import { SpeciesId } from '@common/domain/valueObject/species';
import { SeedlingsId } from '@common/domain/entity/seedlings';
import { UseMasterStateResponse } from '@frontend/store/master/action';
import { limit } from '@frontend/hooks/firestore/review/common';
import { Review } from '@common/domain/entity/review';
import { StorageRepository } from '@frontend/repository/storage';
import { StorageHooks } from '@frontend/hooks/storage';
import storageDirPath = StorageHooks.Review.storageDirPath;

export type UserReview_ListParams = {
  speciesId: SpeciesId;
  seedlingsId: SeedlingsId;
  fetchIfNotFetched: UseMasterStateResponse['fetchIfNotFetched'];
};
export const useFs_Review_list = (
  params: UserReview_ListParams,
): UseReadMoreResponse<Review> => {
  const { speciesId, seedlingsId, fetchIfNotFetched } = params;

  const manager = new FsAppManager.Review(speciesId, seedlingsId);

  return useReadMore<Review>([speciesId, seedlingsId], async ({ startAfter }) => {
    const res = await FSAppRepository.getItems(manager, {
      orderBy: [
        { key: 'good.count', dir: 'desc' },
        { key: 'createdAt', dir: 'desc' },
      ],
      ...(startAfter ? { startAfter } : {}),
      limit,
    });

    const userIds = res.items.map(i => i.value.userId);
    await fetchIfNotFetched(userIds);

    return res;
  });
};
