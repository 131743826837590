import { useToast } from '@chakra-ui/react';
import type { AlertStatus } from '@chakra-ui/react';

export const useMyToast = () => {
  const toast = useToast();

  const showToast = (message: string, status: AlertStatus = 'success') =>
    toast({
      title: message,
      status,
      position: 'bottom-right',
      duration: 9000,
      isClosable: true,
    });

  return {
    toast: showToast,
  };
};
