import React from 'react';
import { Text } from '@chakra-ui/react';
import { HeaderMenu } from '@frontend/components/atoms/HeaderMenu';
import { Routing } from '@frontend/settings/routing';
import { useAuthState } from '@frontend/store/auth/action';
import { AuthRepository } from '@frontend/repository/auth';

export type MyHeaderProps = {};

export const MyHeader: React.FC<MyHeaderProps> = () => {
  const toLoginPage = Routing.Params.Login.useNavigate();
  const toUserSettingsPage = Routing.Params.UserSettings.useNavigate();
  const toAboutPage = Routing.Params.About.useNavigate();
  const toTop = Routing.Params.Top.useNavigate();
  const { user, setUser } = useAuthState();

  const signOut = async () => {
    await AuthRepository.signOut();
    setUser(undefined);
    toLoginPage();
  };

  return (
    <div className="MyHeader">
      <Text className="Title" onClick={toTop} role="link">
        タネログ
      </Text>
      <HeaderMenu
        toSignInPage={toLoginPage}
        onSignOut={signOut}
        toUserSettingsPage={toUserSettingsPage}
        toAboutPage={toAboutPage}
        userName={user?.name}
      />
    </div>
  );
};
