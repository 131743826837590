import React from 'react';
import cn from 'classnames';
import { Segmented } from 'antd';
import 'antd/lib/segmented/style/css';
import { LabeledEnumConstItem } from '@common/types/type';
import { Control, useController } from 'react-hook-form';
import { typedToUpperCase } from '@common/support/functions';

type Value = string | number;

type SegmentItem<VALUE extends Value> = {
  label: string;
  value: VALUE;
};

export type SegmentButtonProps<VALUE extends Value> = {
  className?: string;
  label?: string;
  options: SegmentItem<VALUE>[];
  control: Control<any, any>;
  name: string;
  disabled?: boolean;
};

export const labeledEnumConstToItem = <VALUE extends string>(
  item: LabeledEnumConstItem<VALUE, string>,
): SegmentItem<VALUE> => {
  return {
    value: item.KEY,
    label: item.LABEL,
  };
};

export function SegmentButton<VALUE extends Value>(props: SegmentButtonProps<VALUE>) {
  const { className, name, control, label, options, disabled } = props;
  const controller = useController({ control, name });

  const segmentName = typedToUpperCase(name);
  const selectedItemLabel = () => {
    return options.find(i => i.value === controller.field.value)?.label || '';
  };

  return (
    <div className={cn('SegmentButton', segmentName, className)}>
      {/* 選択中のアイテムにスタイルを適用したい */}
      <style
        dangerouslySetInnerHTML={{
          __html: `.${segmentName} [title=${selectedItemLabel()}] { font-weight: bold}`,
        }}
      />

      {label && (
        <label htmlFor={name} className="Label">
          {label}
        </label>
      )}
      <Segmented {...controller.field} options={options} disabled={disabled} />
    </div>
  );
}
