import React from 'react';
import cn from 'classnames';

export type PageTitleProps = {
  className?: string;
  title: string;
};

export const PageTitle: React.FC<PageTitleProps> = props => {
  const { className, title } = props;
  return <div className={cn('PageTitle', className)}>{title}</div>;
};
