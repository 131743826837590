import React from 'react';
import cn from 'classnames';
import { labeledEnumConstKeyToLabel } from '@common/support/labeledEnumConst';
import { PURPOSE, PurposeKey } from '@common/domain/entity/review/consts';

export type PurposeBadgeProps = {
  className?: string;
  purpose?: PurposeKey;
};

export const PurposeBadge: React.FC<PurposeBadgeProps> = props => {
  const { className, purpose } = props;
  const label = purpose && labeledEnumConstKeyToLabel(PURPOSE, purpose);
  return (
    <div className={cn('PurposeBadge', className)}>
      <span>{label}</span>
    </div>
  );
};
