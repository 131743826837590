import { Entity } from '@common/domain/entity/common';
import { Opaque } from 'type-fest';
import { AreaKey, PurposeKey } from '@common/domain/entity/review/consts';
import { ReviewId } from '@common/domain/entity/review';

abstract class UserBase {
  readonly id: UserId;
  readonly name: string;
  readonly defaultPurpose: PurposeKey;
  readonly defaultArea: AreaKey;
  readonly reviewCount: number;
  readonly goodReviews: ReviewId[];

  protected constructor(props: UserBase) {
    this.id = props.id;
    this.name = props.name;
    this.defaultPurpose = props.defaultPurpose;
    this.defaultArea = props.defaultArea;
    this.reviewCount = props.reviewCount;
    this.goodReviews = props.goodReviews;
  }
}

export type UserId = Opaque<string, 'UserId'>;
export class User extends UserBase implements Entity {
  static createDefault(id: string) {
    return new User({
      id: id as UserId,
      name: '',
      defaultPurpose: 'kitchen',
      defaultArea: 'un-selected',
      reviewCount: 0,
      goodReviews: [],
    });
  }

  constructor(props: UserBase) {
    super(props);
  }
}
