import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';
import { useController, useForm } from 'react-hook-form';
import { MyAlert } from '@frontend/components/atoms/MyAlert';

export type SearchInputProps = {
  className?: string;
  defaultValue: string;
  onSearch: (searchString: string) => void;
};

type Inputs = { search: string };
export const SearchInput: React.FC<SearchInputProps> = props => {
  const { className, defaultValue, onSearch } = props;

  const [isComposing, setIsComposing] = useState(false);

  const { control, reset, formState, getValues } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      search: defaultValue,
    },
  });
  const controller = useController({
    control,
    name: 'search',
    rules: { maxLength: { value: 40, message: '40文字以内で入力してください。' } },
  });

  useEffect(() => {
    reset({ search: defaultValue });
  }, [defaultValue]);

  const error = formState.errors.search;

  const onSearchHandler = () => {
    onSearch(getValues().search);
  };

  const onKeyDownHandler = (key: string) => {
    if (key !== 'Enter') return;
    if (isComposing) return;
    onSearchHandler();
  };

  return (
    <InputGroup className={cn('SearchInput', className)}>
      <InputLeftElement pointerEvents="none">
        <MdSearch className="SearchIcon" size={22} />
      </InputLeftElement>
      <Input
        type="text"
        placeholder="名前の先頭の文字で検索"
        {...controller.field}
        onKeyDown={e => onKeyDownHandler(e.key)}
        onCompositionStart={() => setIsComposing(true)}
        onCompositionEnd={() => setIsComposing(false)}
      />
      <MyAlert active={!!error} title={error?.message || ''} />
      <InputRightElement width="4.5rem" onClick={onSearchHandler}>
        <div>検索</div>
      </InputRightElement>
    </InputGroup>
  );
};
