import React from 'react';
import cn from 'classnames';
import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react';

export type MyAlertProps = {
  className?: string;
  active: boolean;
  title: React.ReactNode;
  description?: React.ReactNode;
};

export const MyAlert: React.FC<MyAlertProps> = props => {
  const { className, active, title, description } = props;

  if (!active) return null;

  return (
    <Alert className={cn('MyAlert', className)} status="error">
      <AlertIcon />
      <AlertTitle>{title}</AlertTitle>
      {description && <AlertDescription>{description}</AlertDescription>}
    </Alert>
  );
};
