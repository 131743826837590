import React, { useRef } from 'react';
import cn from 'classnames';
import { Routing } from '@frontend/settings/routing';
import { useFs_Seedlings } from '@frontend/hooks/firestore/seedlings';
import { SeedlingsCard } from '@frontend/components/atoms/SeedlingsCard';
import { GoBackLink } from '@frontend/components/atoms/GoBackLink';
import { MyButton } from '@frontend/components/atoms/MyButton';
import { useAuthState } from '@frontend/store/auth/action';
import { LoadingBlockModal } from '@frontend/components/molecules/LoadingBlockModal';
import { ReviewCardList } from '@frontend/components/organisms/ReviewCardList';
import { useUiState } from '@frontend/store/ui/action';
import { useFs } from '@frontend/hooks/firestore';
import { MyHead } from '@frontend/components/common/Head';

export type ReviewPageProps = {
  className?: string;
};

export const ReviewPage: React.FC<ReviewPageProps> = props => {
  const { className } = props;

  const { isAuthed } = useAuthState();

  const { speciesId, seedlingsId } = Routing.Params.Reviews.useParams();
  const navigator = React.useCallback(
    Routing.Params.ReviewsCreate.useNavigate().bind(null, speciesId, seedlingsId),
    [speciesId, seedlingsId],
  );
  const seedlingsPageNavigator = Routing.Params.Seedlings.useNavigate().bind(
    null,
    speciesId,
  );

  const { openAuthBlockModal } = useUiState();

  const createReview = () => {
    if (!isAuthed) {
      openAuthBlockModal('レビューの投稿');
      return;
    }
    navigator();
  };

  const { seedlings } = useFs.Seedlings.byId(speciesId, seedlingsId);

  const ogImage =
    (seedlings?.image.referenceName?.includes('楽天') && seedlings?.image.imageUrl) ||
    undefined;

  return (
    <div className="ReviewPage">
      <MyHead pageTitle={`「${seedlings?.displayName}」のレビュー`} ogImage={ogImage} />
      <div className="Section GoBackLinkContainer">
        <GoBackLink navigator={seedlingsPageNavigator} />
      </div>

      <div className={cn('Section', 'Card', className)}>
        <SeedlingsCard seedlings={seedlings} type="pickup" />
      </div>

      <div className="Section Actions">
        <MyButton title="レビューを投稿する" onClick={createReview} />
      </div>

      <div className="Section">
        <ReviewCardList speciesId={speciesId} seedlingsId={seedlingsId} />
      </div>

      <LoadingBlockModal isLoading={false} />
      <div className="Spacer" />
    </div>
  );
};
