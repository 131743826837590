import {
  getStorage as _getStorage,
  ref,
  uploadBytes,
  StorageReference,
  deleteObject,
  getDownloadURL,
  listAll,
} from 'firebase/storage';

export namespace StorageRepository {
  const getStorageRef = (): StorageReference => {
    const s = _getStorage();
    return ref(s);
  };

  const getStorageItemRef = (filePath: string) => {
    const storageRef = getStorageRef();
    return ref(storageRef, filePath);
  };

  export const getDownloadUrls = async (dirPath: string): Promise<string[]> => {
    const dirRef = getStorageItemRef(dirPath);
    const items = await listAll(dirRef);
    return await Promise.all(
      items.items.map(item => {
        return getDownloadURL(getStorageItemRef(item.fullPath));
      }),
    );
  };

  export const uploadImage = async (filePath: string, file: File) => {
    const itemRef = getStorageItemRef(filePath);
    await uploadBytes(itemRef, file);
  };

  export const deleteItems = async (dirPath: string) => {
    const dirRef = getStorageItemRef(dirPath);
    const items = await listAll(dirRef);

    for (const item of items.items) {
      const path = item.fullPath;
      console.log('delete path: ', path);
      const ref = getStorageItemRef(path);
      await deleteObject(ref);
    }
  };
}
