import { LabeledEnumConstKeys, ValueOf } from '@common/types/type';

export const SOIL_COMPONENT = {
  PETTING_SOIL: { KEY: 'petting-soil', LABEL: '培養土' },
  AKADAMA: { KEY: 'akadama', LABEL: '赤玉土' },
  KANUMA: { KEY: 'kanuma', LABEL: '鹿沼土' },
  MULCH: { KEY: 'mulch', LABEL: '腐葉土' },
  COMPOST: { KEY: 'compost', LABEL: '堆肥' },
  PEAT_MOSS: { KEY: 'peat-moss', LABEL: 'ピートモス' },
  VERMICULITE: { KEY: 'vermiculite', LABEL: 'バーミキュライト' },
  PERLITE: { KEY: 'perlite', LABEL: 'パーライト' },
  SAND: { KEY: 'sand', LABEL: '川砂' },
} as const;
export type SoilComponentKey = ValueOf<typeof SOIL_COMPONENT>['KEY'];
export type SoilComponentLabel = ValueOf<typeof SOIL_COMPONENT>['LABEL'];
const _SOIL_COMPONENTS_ARRAY = Object.values(SOIL_COMPONENT);
export const getSoilComponentLabel = (key: SoilComponentKey): SoilComponentLabel => {
  return _SOIL_COMPONENTS_ARRAY.find(i => i.KEY === key)!.LABEL;
};

export class SoilComponentItem {
  name: SoilComponentKey;
  ratio: number;

  constructor(props: SoilComponentItem) {
    this.name = props.name;
    this.ratio = props.ratio;
  }
}

export const PURPOSE = {
  NAME: 'purpose',
  LABEL: '目的',
  ITEMS: [
    { KEY: 'kitchen', LABEL: '家庭菜園' },
    { KEY: 'agriculture', LABEL: '農業' },
  ],
} as const;
export type PurposeKey = LabeledEnumConstKeys<any, any, typeof PURPOSE>;
export const UN_SELECTED = {
  KEY: 'un-selected',
  LABEL: '未選択',
} as const;
export const AREA = {
  NAME: 'area',
  LABEL: '地域',
  ITEMS: [
    UN_SELECTED,
    { KEY: 'warm', LABEL: '暖地' },
    { KEY: 'normal', LABEL: '中間地' },
    { KEY: 'cold', LABEL: '冷涼地' },
  ],
} as const;
export type AreaKey = LabeledEnumConstKeys<any, any, typeof AREA>;
export const SEASON = {
  NAME: 'season',
  LABEL: '植付時期',
  ITEMS: [
    UN_SELECTED,
    { KEY: 'spring', LABEL: '春' },
    { KEY: 'summer', LABEL: '夏' },
    { KEY: 'autumn', LABEL: '秋' },
    { KEY: 'winter', LABEL: '冬' },
  ],
} as const;
export type SeasonKey = LabeledEnumConstKeys<any, any, typeof SEASON>;
export const SEEDLINGS_TYPE = {
  NAME: 'seedlingsType',
  LABEL: '苗種',
  ITEMS: [
    UN_SELECTED,
    { KEY: 'seedling', LABEL: '実生' },
    { KEY: 'grafting', LABEL: '接木' },
    { KEY: 'cuttings', LABEL: '挿木' },
    { KEY: 'wood', LABEL: '取り木' },
  ],
} as const;
export type SeedlingsTypeKey = LabeledEnumConstKeys<any, any, typeof SEEDLINGS_TYPE>;
export const PLANTING_PLACE = {
  NAME: 'plantingPlace',
  LABEL: '定植場所',
  ITEMS: [
    UN_SELECTED,
    { KEY: 'planter', LABEL: 'プランター' },
    { KEY: 'ground', LABEL: '地植え' },
    { KEY: 'other', LABEL: 'その他' },
  ],
} as const;
export type PlantingPlaceKey = LabeledEnumConstKeys<any, any, typeof PLANTING_PLACE>;
export const PLANTING_ENVIRONMENT = {
  NAME: 'plantingEnvironment',
  LABEL: '育成環境',
  ITEMS: [
    UN_SELECTED,
    { KEY: 'balcony', LABEL: 'ベランダ' },
    { KEY: 'outdoor', LABEL: '露地' },
    { KEY: 'vinyl-house', LABEL: 'ハウス' },
    { KEY: 'in-house', LABEL: '室内' },
    { KEY: 'greenhouse', LABEL: '温室' },
  ],
} as const;
export type PlantingEnvironmentKey = LabeledEnumConstKeys<
  any,
  any,
  typeof PLANTING_ENVIRONMENT
>;
export const METHOD = {
  NAME: 'method',
  LABEL: '栽培方法',
  ITEMS: [
    UN_SELECTED,
    { KEY: 'soil', LABEL: '土耕' },
    { KEY: 'water', LABEL: '水耕' },
    { KEY: 'hydroponics', LABEL: '溶液' },
    { KEY: 'other', LABEL: 'その他' },
  ],
} as const;
export type MethodKey = LabeledEnumConstKeys<any, any, typeof METHOD>;
