import { FsAppManager } from '@fs/manager/app';
import { User, UserId } from '@common/domain/entity/user';
import { FSAppRepository } from '@frontend/repository/firestore/index';
import { RefValue } from '@frontend/support/type';
import { ReviewId } from '@common/domain/entity/review';

const manager = new FsAppManager.User();

export namespace UserAppRepository {
  export const updateGoodReviews = async (
    id: UserId,
    reviewId: ReviewId,
    type: 'add' | 'remove',
  ): Promise<void> => {
    const curUser = await FSAppRepository.getItem(manager, id);

    if (!curUser) throw new Error('user not exists');

    await FSAppRepository.update(manager, id, {
      goodReviews:
        type === 'add'
          ? [...curUser.value.goodReviews, reviewId]
          : curUser.value.goodReviews.filter(i => i !== reviewId),
    });
  };

  export const listenById = (
    userId: UserId,
    onListen: (user: RefValue<User>) => void,
  ) => {
    return FSAppRepository.listenById(manager, userId, onListen);
  };
}
