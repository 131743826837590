import React from 'react';
import cn from 'classnames';
import { Button } from '@chakra-ui/react';

export type MyButtonProps = {
  className?: string;
  title: React.ReactNode;
  onClick: () => void;
  active?: boolean;
  autoFocus?: boolean;
  isLoading?: boolean;
};

export const MyButton: React.FC<MyButtonProps> = props => {
  const { className, title, active = true, onClick, ...rest } = props;

  const onClickHandler = () => {
    if (!active) return;
    onClick();
  };

  return (
    <Button
      className={cn('MyButton', className, { Active: active })}
      onClick={onClickHandler}
      {...rest}
    >
      {title}
    </Button>
  );
};
