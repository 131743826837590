import { Opaque } from 'type-fest';

class OrganizationBase {
  readonly name: string;
  readonly displayName: string;
  readonly pronunciation?: string;
  readonly url?: string;

  protected constructor(props: OrganizationBase) {
    this.name = props.name;
    this.displayName = props.displayName;
    this.pronunciation = props.pronunciation;
    this.url = props.url;
  }
}

export type OrganizationId = Opaque<string, 'OrganizationId'>;
export class Organization extends OrganizationBase {
  static generateId(name: string): OrganizationId {
    return name as OrganizationId;
  }

  get id() {
    return Organization.generateId(this.name);
  }

  constructor(props: OrganizationBase) {
    super(props);
  }
}
