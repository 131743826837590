import { Helmet } from 'react-helmet';
import React from 'react';

const DefaultBannerImage = 'https://tanelog.hedrall.work/images/logo/logo.png';

type MyHeadParams = {
  pageTitle: string;
  ogImage?: string;
};

export const MyHead: React.FC<MyHeadParams> = props => {
  const { pageTitle, ogImage = DefaultBannerImage } = props;
  const title = `タネログ | ${pageTitle}`;
  const url = window.location.href;

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content="test" />
      <meta property="og:site_name" content="OGPに掲載するサイト名" />
      <meta property="og:image" content={ogImage} />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:image" content={ogImage} />
    </Helmet>
  );
};
