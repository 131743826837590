import React from 'react';
import cn from 'classnames';
import { Routing } from '@frontend/settings/routing';
import { RefValue } from '@frontend/support/type';
import { Seedlings, SeedlingsId } from '@common/domain/entity/seedlings';
import { SpeciesId } from '@common/domain/valueObject/species';
import { SeedlingsCard } from '@frontend/components/atoms/SeedlingsCard';

export type SeedlingsCardListProps = {
  className?: string;
  itemList: (RefValue<Seedlings>[] | undefined)[];
  speciesId: SpeciesId;
};

const renderCards = (
  navigator: (speciesId: SeedlingsId) => void,
  items: RefValue<Seedlings>[] | undefined,
  listIndex: number,
) => {
  return (items || Array(12).fill(undefined)).map((_item, index) => {
    const item = _item?.value as Seedlings | undefined;
    return (
      <SeedlingsCard
        seedlings={item}
        navigator={navigator}
        key={`${listIndex}-${index}`}
        type="list"
      />
    );
  });
};

export const SeedlingsCardList: React.FC<SeedlingsCardListProps> = props => {
  const { className, itemList, speciesId } = props;
  const navigator = Routing.Params.Reviews.useNavigate().bind(null, speciesId);
  return (
    <div className={cn('SeedlingsCardList', className)}>
      <div className="Items">
        {itemList.map((list, index) => {
          return renderCards(navigator, list, index);
        })}
      </div>
    </div>
  );
};
