import React from 'react';
import cn from 'classnames';
import classNames from 'classnames';
import { UseReadMoreResponse } from '@frontend/hooks/firestore/readMore';
import { Entity } from '@common/domain/entity/common';

export type ReadMoreWrapperProps<T extends Entity> = {
  children: React.ReactNode;
  className?: string;
  useReadMoreResponse: UseReadMoreResponse<T>;
};

export function ReadMoreWrapper<T extends Entity>(props: ReadMoreWrapperProps<T>) {
  const { children, className, useReadMoreResponse } = props;
  const { noMorePages, isFetching, readMore } = useReadMoreResponse;
  return (
    <div className={cn('ReadMoreWrapper', className)}>
      {children}
      <div className="ReadMoreContainer">
        {!noMorePages && (
          <div
            className={classNames('ReadMore', { Active: !isFetching })}
            onClick={readMore}
          >
            もっとみる
          </div>
        )}
      </div>
    </div>
  );
}
