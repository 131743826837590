import React from 'react';
import cn from 'classnames';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { MyButton } from '@frontend/components/atoms/MyButton';
import { Routing } from '@frontend/settings/routing';
import { useUiState } from '@frontend/store/ui/action';

export type AuthBlockModalProps = {
  className?: string;
};

export const AuthBlockModal: React.FC<AuthBlockModalProps> = props => {
  const { className } = props;
  const toLogin = Routing.Params.Login.useNavigate();
  const {
    uiState: {
      authBlockModal: { isOpen, actionName },
    },
    closeAuthBlockModal,
  } = useUiState();

  const onOkHandler = () => {
    closeAuthBlockModal();
    toLogin();
  };

  return (
    <Modal isOpen={isOpen} onClose={closeAuthBlockModal}>
      <ModalOverlay />
      <ModalContent className={cn('AuthBlockModal', className)}>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody>
          {actionName}にはログインが必要です。ユーザ登録ページに移動しますか？
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={closeAuthBlockModal}>
            閉じる
          </Button>
          <MyButton title="移動する" onClick={onOkHandler} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
