import React from 'react';
import cn from 'classnames';
import { Control, useFieldArray } from 'react-hook-form';
import { SoilComponentsItem } from '@frontend/components/atoms/SoilComponentsItem';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ValueOf } from '@common/types/type';
import { SoilComponentPieChart } from '@frontend/components/atoms/SoilComponentPieChart';
import {
  SOIL_COMPONENT,
  SoilComponentItem,
  SoilComponentKey,
} from '@common/domain/entity/review/consts';

export type SoilComponentsInputProps = {
  className?: string;
  control: Control<any, any>;
  value: SoilComponentItem[];
};

const renderAppendButton = ({
  unselectedItems,
  onAppend,
}: {
  unselectedItems: ValueOf<typeof SOIL_COMPONENT>[];
  onAppend: (key: SoilComponentKey) => void;
}) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        aria-label="Options"
        leftIcon={<AddIcon />}
        variant="outline"
        size="sm"
      >
        用土を追加する
      </MenuButton>
      <MenuList>
        {unselectedItems.map(i => {
          return (
            <MenuItem key={i.KEY} onClick={() => onAppend(i.KEY)}>
              {i.LABEL}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

const renderLabels = (isShow: boolean) =>
  isShow && (
    <div className="SoilComponentsItemHeader">
      <label>用土名</label>
      <label>割合</label>
    </div>
  );

const SoilComponents = Object.values(SOIL_COMPONENT);

const renderItems = (
  fields: { id: string }[],
  control: Control<any, any>,
  remove: (index?: number | number[]) => void,
) =>
  fields.map((field, index) => {
    return (
      <SoilComponentsItem
        key={field.id}
        control={control}
        index={index}
        onRemove={() => remove(index)}
      />
    );
  });

export const SoilComponentsInput: React.FC<SoilComponentsInputProps> = props => {
  const { className, control, value } = props;
  const { fields, append, remove } = useFieldArray({ control, name: 'soilComponents' });

  const appendHandler = (key: SoilComponentKey) => {
    append({ name: key, ratio: 1 });
  };

  const unselectedItems = SoilComponents.filter(i => !value.find(v => v.name === i.KEY));

  return (
    <div className={cn('SoilComponentsInput', className)}>
      <label>用土</label>

      {renderLabels(fields.length !== 0)}

      {renderItems(fields, control, remove)}

      <div className="AddButtonContainer">
        {renderAppendButton({ unselectedItems, onAppend: appendHandler })}
      </div>

      {value.length !== 0 && <SoilComponentPieChart value={value} />}
    </div>
  );
};
