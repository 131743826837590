import { useRecoilState } from 'recoil';
import { UI_STATE, UiState } from '@frontend/store/ui/atom';

export type UseUiStateResponse = {
  openAuthBlockModal: (actionName: string) => void;
  closeAuthBlockModal: () => void;
  uiState: UiState;
};

export const useUiState = (): UseUiStateResponse => {
  const [state, setState] = useRecoilState(UI_STATE);

  const openAuthBlockModal = (actionName: string) => {
    setState(pre => {
      return {
        ...pre,
        authBlockModal: {
          isOpen: true,
          actionName,
        },
      };
    });
  };

  const closeAuthBlockModal = () => {
    setState(pre => {
      return {
        ...pre,
        authBlockModal: {
          isOpen: false,
          actionName: '',
        },
      };
    });
  };

  return {
    openAuthBlockModal,
    closeAuthBlockModal,
    uiState: state,
  };
};
