import { SpeciesId } from '@common/domain/valueObject/species';
import {
  useReadMore,
  UseReadMoreFetcher,
  UseReadMoreResponse,
} from '@frontend/hooks/firestore/readMore';
import { Seedlings } from '@common/domain/entity/seedlings';
import { FsAppManager } from '@fs/manager/app';
import { FSAppRepository } from '@frontend/repository/firestore';
import * as fs from 'firebase/firestore';
import { limit } from '@frontend/hooks/firestore/seedlings/common';

export const useFs_Seedlings_list = (
  speciesId: SpeciesId,
  searchString?: string,
): UseReadMoreResponse<Seedlings> => {
  const fetcher: UseReadMoreFetcher<Seedlings> = async ({ startAfter }) => {
    const manager = new FsAppManager.Seedlings(speciesId);
    console.log('search', { searchString, startAfter });
    return await FSAppRepository.getItems(manager, {
      wheres: searchString
        ? [
            fs.where('name.kanji', '>=', searchString),
            fs.where('name.kanji', '<=', searchString + '\uf8ff'),
          ]
        : undefined,
      orderBy: [
        ...(searchString ? [{ key: 'name.kanji', dir: 'desc' } as const] : []),
        { key: 'reviewCount', dir: 'desc' },
        { key: 'image.imageUrl', dir: 'desc' },
      ],
      ...(startAfter ? { startAfter } : {}),
      limit,
    });
  };
  return useReadMore<Seedlings>([speciesId, searchString], fetcher);
};
