import { SeedlingsId } from '@common/domain/entity/seedlings';
import { UserId } from '@common/domain/entity/user';
import { Entity } from '@common/domain/entity/common';
import dayjs from 'dayjs';
import { Opaque } from 'type-fest';
import {
  AreaKey,
  MethodKey,
  PlantingEnvironmentKey,
  PlantingPlaceKey,
  PurposeKey,
  SeasonKey,
  SeedlingsTypeKey,
  SoilComponentItem,
} from '@common/domain/entity/review/consts';

class ReviewBase {
  // relation
  readonly seedlingsId: SeedlingsId;
  readonly userId: UserId;

  readonly body: string; // レビュー本文
  readonly purpose: PurposeKey; // 目的
  readonly area: AreaKey;
  readonly season: SeasonKey;
  readonly seedlingsType: SeedlingsTypeKey;
  readonly plantingPlace: PlantingPlaceKey;
  readonly plantingEnvironment: PlantingEnvironmentKey;
  readonly method: MethodKey;
  readonly yield: string;
  readonly createdAt: string;

  // fertilizer: {
  //   name: string, // 化成肥料
  // }[],

  soilComponents: SoilComponentItem[];

  good: {
    count: number;
    byUserIds: UserId[];
  };

  constructor(props: ReviewBase) {
    this.seedlingsId = props.seedlingsId;
    this.userId = props.userId;
    this.body = props.body;
    this.purpose = props.purpose;
    this.area = props.area;
    this.season = props.season;
    this.seedlingsType = props.seedlingsType;
    this.plantingEnvironment = props.plantingEnvironment;
    this.plantingPlace = props.plantingPlace;
    this.method = props.method;
    this.yield = props.yield;
    this.soilComponents = props.soilComponents.map(i => new SoilComponentItem(i));
    this.createdAt = props.createdAt;
    this.good = props.good;
  }
}

export type ReviewId = Opaque<string, 'ReviewId'>;

export class Review extends ReviewBase implements Entity {
  static generateId = (createdAt: string, userId: string) => {
    return [createdAt, userId].join('-') as ReviewId;
  };

  get id(): ReviewId {
    return Review.generateId(this.createdAt, this.userId);
  }

  public get canSelectSoilComponents() {
    return this.plantingPlace === 'planter';
  }

  public get isSoilComponentsSelected() {
    return this.soilComponents.length !== 0;
  }

  private validate() {
    if (!this.canSelectSoilComponents && this.isSoilComponentsSelected) {
      throw new Error('用土を選択できるのは、プランターの場合のみです。');
    }
  }

  static createNewReview = (
    props: Omit<ReviewBase, 'good' | 'createdAt'>,
    createdAt = dayjs().toISOString(),
  ) => {
    return new Review({
      ...props,
      createdAt,
      good: {
        count: 0,
        byUserIds: [],
      },
    });
  };

  constructor(props: ReviewBase) {
    super(props);
    this.validate();
  }
}
