import { Seedlings } from '@common/domain/entity/seedlings';
import { Species } from '@common/domain/valueObject/species';
import { User } from '@common/domain/entity/user';
import { Entity } from '@common/domain/entity/common';
import type fs from 'firebase/firestore';
import { SoilComponentItem } from '@common/domain/entity/review/consts';
import { Review } from '@common/domain/entity/review';

export const dropUndefined = (obj: { [key: string]: any }) => {
  Object.keys(obj).map(key => {
    const value = obj[key];
    if (value === undefined) {
      obj[key] = null;
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      obj[key] = dropUndefined(value);
    }
  });
  return obj;
};

export const basicToFirestore = <T>(item: T): fs.DocumentData => {
  return dropUndefined({ ...item });
};

export const basicFromFirestore = <T extends Entity>(
  construct: new (...args: any[]) => T,
) => {
  return (snapshot: fs.QueryDocumentSnapshot<fs.DocumentData>): T => {
    const data = snapshot.data() as T;

    return new construct(data);
  };
};

export const basicConverter = <T extends Entity>(
  construct: new (...args: any[]) => T,
): fs.FirestoreDataConverter<T> => {
  return {
    toFirestore: basicToFirestore,
    fromFirestore: basicFromFirestore(construct),
  };
};

export const reviewConverter: fs.FirestoreDataConverter<Review> = {
  toFirestore: domain => {
    return {
      seedlingsId: domain.seedlingsId,
      userId: domain.userId,
      body: domain.body,
      purpose: domain.purpose,
      area: domain.area,
      season: domain.season,
      seedlingsType: domain.seedlingsType,
      plantingPlace: domain.plantingPlace,
      plantingEnvironment: domain.plantingEnvironment,
      method: domain.method,
      yield: domain.yield,
      createdAt: domain.createdAt,
      soilComponents: (domain.soilComponents as SoilComponentItem[]).map(i => ({
        ...i,
      })),
      good: {
        ...domain.good,
      },
    };
  },
  fromFirestore: (snapshot: fs.QueryDocumentSnapshot<fs.DocumentData>) => {
    const review = snapshot.data() as Review;

    return new Review({
      ...review,
    });
  },
};

export const speciesConverter: fs.FirestoreDataConverter<Species> = {
  toFirestore: domain => {
    return {
      id: domain.id,
      cropClass: domain.cropClass,
      name: domain.name,
      displayName: domain.displayName,
      nameOnDb: domain.nameOnDb,
      image: domain.image && {
        ...domain.image,
      },
      seedlingsCount: domain.seedlingsCount,
      reviewCount: domain.reviewCount,
    };
  },
  fromFirestore: basicFromFirestore(Species),
};

export const appConverters = {
  seedlings: basicConverter(Seedlings),
  species: basicConverter(Species),
  review: reviewConverter,
  user: basicConverter(User),
} as const;
