import React, { useEffect } from 'react';
import { AspectRatio, Fade, Image, useDisclosure } from '@chakra-ui/react';
import classNames from 'classnames';

export type MyCardProps = {
  src?: 'no' | (string & {});
  imageReferrer?: {
    url?: string;
    name?: string;
  };
  title: React.ReactNode;
  subtitle?: string;
  reviewCount?: number;
  seedlingsCount?: number;
  onClick?: () => void;
  className?: string;
};

export const MyCard: React.FC<MyCardProps> = props => {
  const {
    src: _src,
    title,
    subtitle,
    reviewCount,
    seedlingsCount,
    onClick,
    imageReferrer,
    className,
  } = props;

  const src = _src !== 'no' ? _src : '/images/noImage.jpg';

  return (
    <div className={classNames('MyCard', className)} onClick={onClick} role="link">
      {src && (
        <AspectRatio maxW="100%" ratio={16 / 9}>
          <Image className="Img" src={src} />
        </AspectRatio>
      )}
      <div className="Content">
        {imageReferrer && (
          <div
            className="ImageReferrer"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <a href={imageReferrer.url} target="_blank" rel="noreferrer">
              画像: {imageReferrer.name}
            </a>
          </div>
        )}
        <div className="TitleContainer">
          <div className="Title">{title}</div>
          {subtitle ? <div className="Sub">{subtitle}</div> : null}
        </div>
        {seedlingsCount === undefined ? null : (
          <div className="Review">掲載品種: {seedlingsCount}</div>
        )}
        {reviewCount === undefined ? null : (
          <div className="Review">レビュー: {reviewCount}</div>
        )}
      </div>
    </div>
  );
};
