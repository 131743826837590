import { FsAppManager } from '@fs/manager/app';
import { CropClassKey, Species } from '@common/domain/valueObject/species';
import { useReadMore, UseReadMoreResponse } from '@frontend/hooks/firestore/readMore';
import { FSAppRepository } from '@frontend/repository/firestore';
import * as fs from 'firebase/firestore';

const limit = 12;
const manager = new FsAppManager.Species();

export const useFs_Species_List = (
  keys: CropClassKey[],
): UseReadMoreResponse<Species> => {
  return useReadMore<Species>(keys, async ({ startAfter }) => {
    return await FSAppRepository.getItems(manager, {
      wheres: [fs.where('cropClass', 'in', keys)],
      orderBy: [
        { key: 'reviewCount', dir: 'desc' },
        { key: 'seedlingsCount', dir: 'desc' },
      ],
      ...(startAfter ? { startAfter } : {}),
      limit: limit,
    });
  });
};
