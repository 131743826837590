import { atom } from 'recoil';

export type UiState = {
  authBlockModal: {
    isOpen: boolean;
    actionName: string;
  };
};

export const UI_STATE = atom<UiState>({
  key: 'Ui',
  default: {
    authBlockModal: {
      isOpen: false,
      actionName: '',
    },
  },
});
