import React from 'react';
import { useNavigate } from 'react-router-dom';

export type GoBackLinkProps = {
  linkUrl?: string;
  navigator?: () => void;
};

export const GoBackLink: React.FC<GoBackLinkProps> = props => {
  const { linkUrl, navigator: _navigator } = props;

  const navigator = useNavigate();

  const back = () => {
    if (_navigator) return _navigator();

    if (linkUrl) {
      navigator(linkUrl);
      return;
    }
    navigator(-1);
  };

  return (
    <span className="GoBackLink" onClick={back} role="link">
      {'<'} 戻る
    </span>
  );
};
