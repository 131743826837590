import React, { ChangeEvent, ChangeEventHandler, useEffect } from 'react';
import cn from 'classnames';
import { MyButton } from '@frontend/components/atoms/MyButton';
import { SpeciesId } from '@common/domain/valueObject/species';
import { FsAppManager } from '@fs/manager/app';
import { FSAppRepository } from '@frontend/repository/firestore';
import { useAuthState } from '@frontend/store/auth/action';
import { ImageAttrs } from '@common/domain/valueObject/imageAttrs';
import { speciesDefs } from '@frontend/components/pages/Admin/defs/species';
import { Select } from '@chakra-ui/react';
import { useController, useForm } from 'react-hook-form';
import { StorageRepository } from '@frontend/repository/storage';
import { StorageHooks } from '@frontend/hooks/storage';
import { UserId } from '@common/domain/entity/user';
import { BrowserRepository } from '@frontend/repository/browser';
import { ReviewId } from '@common/domain/entity/review';
import dayjs from 'dayjs';

export type AdminPageProps = {
  className?: string;
};

type Inputs = {
  speciesId: SpeciesId;
};
export const AdminPage: React.FC<AdminPageProps> = props => {
  const { className } = props;
  const { user: authUser } = useAuthState();

  const { control, formState, getValues } = useForm<Inputs>({
    defaultValues: {
      speciesId: speciesDefs[0].speciesId as SpeciesId,
    },
  });
  const speciesIdController = useController({ control, name: 'speciesId' });

  useEffect(() => {
    if (!authUser) return;
    if (authUser.id !== 'MHKvjFhuHNQqy631znNdDBsG6lF3') {
      throw new Error('not admin user');
    }
  }, [authUser?.id]);

  const uploader = StorageHooks.Review.useUploader();
  if (!authUser?.id) return null;

  const exec = async () => {
    const manager = new FsAppManager.Species();
    const res = await FSAppRepository.getItems(manager, { limit: 1000 });
    for (const species of res.items) {
      await FSAppRepository.update(manager, species.ref.id, {
        // image: null,
      });
    }
  };

  const updateSpeciesImage = async () => {
    const manager = new FsAppManager.Species();
    for (const item of speciesDefs) {
      const { speciesId, image } = item;
      console.log('>>> check', speciesId);
      const species = await FSAppRepository.getItem(manager, speciesId as SpeciesId);
      const isDiff = Object.entries(image).some(([_key, value]) => {
        const key = _key as keyof ImageAttrs;
        const curImage = species.value.image;
        if (!curImage) return true;
        return value !== curImage[key];
      });
      if (isDiff) {
        console.log('>>> update', speciesId);
        await FSAppRepository.update(manager, speciesId, {
          image: { ...image },
        });
      }
    }
  };

  const handleDownload = (content: string) => {
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, content], { type: 'text/json' });

    // @ts-ignore
    if (window.navigator.msSaveBlob) {
      // @ts-ignore
      window.navigator.msSaveBlob(blob, 'test.json');

      // msSaveOrOpenBlobの場合はファイルを保存せずに開ける
      // @ts-ignore
      window.navigator.msSaveOrOpenBlob(blob, 'test.json');
    } else {
      // @ts-ignore
      document.getElementById('download').href = window.URL.createObjectURL(blob);
      document.getElementById('download')?.click();
    }
  };

  const dumpSeedlings = async () => {
    const { speciesId } = getValues();
    console.log(speciesId);
    if (!speciesId) return;

    const manager = new FsAppManager.Seedlings(speciesId);
    const species = await FSAppRepository.getItems(manager, {
      limit: 1000,
      orderBy: [{ key: 'reviewCount', dir: 'desc' }],
    });

    handleDownload(
      JSON.stringify(
        species.items.map(s => {
          const { reviewCount, ...rest } = s.value;
          return rest;
        }),
        null,
        2,
      ),
    );
  };

  const onSelectFile = async (e: ChangeEvent<any>) => {
    const input = e.target as HTMLInputElement;
    const files = input.files;
    if (!files) return;
    console.log(files);

    await uploader({
      file: (await BrowserRepository.Image.compressFileIfLarge(files[0])).file,
      index: 0,
      userId: 'test-user-id' as UserId,
      createdAt: dayjs().toISOString(),
    });

    // // FileReaderオブジェクトを作成
    // const reader = new FileReader();
    // console.log(reader.readAsDataURL(files[0]));
    //
    // reader.onload = (e: ProgressEvent<FileReader>) => {
    //   if (!e || !e.target) return;
    //   console.log(e.target.result);
    //   return;
    // };
  };

  return (
    <div className={cn('AdminPage', className)}>
      <div className="Section">
        <MyButton title={'実行'} onClick={exec} />
      </div>

      <div className="Section">
        <MyButton title={'UpdateImage'} onClick={updateSpeciesImage} />
      </div>

      <div className="Section">
        <Select {...speciesIdController.field}>
          {speciesDefs.map(speciesDef => {
            const id = speciesDef.speciesId;
            return (
              <option key={id} value={id}>
                {id}
              </option>
            );
          })}
        </Select>
        <MyButton title={'DumpSeedlings'} onClick={dumpSeedlings} />
      </div>

      <div className="Section">
        <input
          type={'file'}
          onChange={onSelectFile}
          accept="image/png, image/jpeg"
          multiple
        />
      </div>

      <a id="download" href="#" download="test.json" style={{ display: 'none' }}>
        ダウンロード
      </a>
    </div>
  );
};
