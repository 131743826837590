import React from 'react';
import cn from 'classnames';
import { INQUIRY_FORM_URL } from '@frontend/settings';
import { MyHead } from '@frontend/components/common/Head';

export type AboutPageProps = {
  className?: string;
};

export const AboutPage: React.FC<AboutPageProps> = props => {
  const { className } = props;
  return (
    <div className={cn('AboutPage', 'Section', className)}>
      <MyHead pageTitle="このサイトについて" />
      <h2>タネログとは (2022年10月 オープン)</h2>
      <p>
        タネログは種苗に関するレビューを投稿できるサイトです。
        投稿いただいたレビューを多くのユーザに見てもらい、
        無数に流通している種苗の数々からお一人お一人に合った種を見つけるためのサポートができればよいと考えています。
        また、趣味で家庭菜園をやられている方から本格的に農業に従事されている方まで多くの方にレビューをいただき、
        相互にノウハウを共有する場としてご活用ください。
      </p>
      <h2>ご注意点</h2>
      <p>
        このサイトは管理人のhedrallが個人で開発・運営しているサイトです。
        そのため、ご利用に当たっては全て自己責任にてお願いいたします。
        このサイトを利用することによって発生したいかなる不利益に関しても保証することができません。
      </p>
      <h2>お願い</h2>
      <p>
        本サイトは個人で運営しているため、みなさま一人一人のマナーと思いやりにより
        良いサイトにしていければと思っていますので、どうぞよろしくお願いいたします🙇‍
      </p>
      <p>
        また、このサイトは公開したばかりなので、もしかすると
        <br />
        - 見たい品種がない!
        <br />
        - もう少しこんな機能があると良いのに！
        <br />
        といったことがあると思います。
        そんな時は、改善に関するご意見を頂けると大変助かります。
        <br />
        <a href={INQUIRY_FORM_URL} target="_blank" rel="noreferrer">
          問い合わせ
        </a>
        <br />
        また、応援のメッセージなども頂けると励みになります！mm
      </p>
      <h2>管理人に関して</h2>
      <p>
        こんにちは！hedrallと申します。本職はITエンジニアで、趣味が家庭菜園になります！
        東京の狭小地ですが屋上での栽培にチャレンジしており、今年は水耕栽培にて大玉スイカを3個、
        小玉メロンを2つ収穫することができました。
      </p>
      <p>
        その他にも、ミニトマト、オクラ、大葉、ズッキーニ、イチゴなどをプランター栽培し、
        グァバ、ジャボチカバ、ホワイトサポテ、カニステル、ポポーなど珍しい果物の苗木作りにもチャレンジしています！
      </p>
      <p>
        最初はブロッコリー1株から初めましたが、虫に食われ収穫できず悔しい思いをしました。
        その悔しさをバネに家庭菜園に熱中する様になったのですが、
        日々の成長を見守り天候や虫害に一喜一憂する日々の充実感や、
        収穫できた時に家族（妻や娘）が喜んでくれることに感動があり、さらに熱中していきました。
      </p>
      <p>
        しかしながら、私自身、園芸の初心者のため日々WebサイトやYouTubeで情報を集めていますが、
        想像以上に色々な情報があり、困惑することがあります。
      </p>
      <p>
        中でも「品種」は非常に迷うことが多くありました。
        「狭いベランダで1年に1回のチャンス！」と思うと、
        少しでも自分や環境にあった良い種苗を選択したいと考えるのですが、
        流通品種は無数にあってどれが良いのか選ぶのは大変なことです。
      </p>
      <p>
        そんな今年の家庭菜園でのことを思い返し、本サイトを考案しました。
        制作期間としては2か月ほどプライベート時間と仕事での知見を活用して、本サイト作りました。
      </p>
      <p>
        家庭菜園、農業をみなさんにとってもっと身近になる様に、
        このサイトをよりよく改善していければと思います。
      </p>
    </div>
  );
};
