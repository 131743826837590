import { SpeciesId } from '@common/domain/valueObject/species';
import { OrganizationId } from '@common/domain/entity/organization';
import { ValueOf } from '@common/types/type';
import { Opaque } from 'type-fest';
import { Entity } from '@common/domain/entity/common';
import { ImageAttrs } from '@common/domain/valueObject/imageAttrs';
import { ORGANIZATIONS } from '@common/master/organizations';

export const REGISTRATION_STATE = {
  NOT_REGISTERED: {
    KEY: 'not-registered',
    LABEL: '未登録',
  },
  IN_PROGRESS: {
    KEY: 'in-progress',
    LABEL: '出願中',
  },
  REGISTERED: {
    KEY: 'registered',
    LABEL: '登録済',
  },
  NOT_RESEARCHED: {
    KEY: 'not-researched',
    LABEL: '未調査',
  },
} as const;
export type RegistrationStateKey = ValueOf<typeof REGISTRATION_STATE>['KEY'];

// 品種
class SeedlingsBase {
  // relation
  readonly speciesId: SpeciesId;
  // 販売元
  readonly distributorId: OrganizationId;

  // 品種名
  readonly name: {
    readonly kanji: string;
    readonly kana: string;
  };

  // productName
  readonly productName: {
    readonly kanji?: string;
    readonly kana?: string;
  };

  // 種苗登録
  readonly registrationStateKey: RegistrationStateKey;

  // 品種の掲載画像
  readonly image: ImageAttrs;

  // レビュー数
  readonly reviewCount: number;

  // 販売中か？
  readonly onSale: boolean;

  // 情報をどこからとったか
  // undefinedの場合は流通品種DB
  readonly registerFrom: 'db' | 'manually' | undefined;

  protected constructor(props: SeedlingsBase) {
    this.speciesId = props.speciesId;
    this.distributorId = props.distributorId;
    this.name = {
      kanji: props.name.kanji,
      kana: props.name.kana,
    };
    this.productName = {
      kanji: props.productName.kanji,
      kana: props.productName.kana,
    };
    this.registrationStateKey = props.registrationStateKey;
    this.image = props.image && {
      imageUrl: props.image.imageUrl,
      referenceUrl: props.image.referenceUrl,
      referenceName: props.image.referenceName,
      selfLicensed: props.image.selfLicensed,
    };
    this.reviewCount = props.reviewCount;
    this.onSale = props.onSale;
    this.registerFrom = props.registerFrom;
  }
}

export type SeedlingsId = Opaque<string, 'SeedlingsId'>;

export class Seedlings extends SeedlingsBase implements Entity {
  get id() {
    return [this.name.kana, this.name.kanji].join('-') as SeedlingsId;
  }

  get displayName() {
    return this.productName.kanji || this.name.kanji;
  }

  static createManually(params: {
    speciesId: SpeciesId;
    id: string;
    displayName: string;
    distributorId: OrganizationId;
  }): Seedlings {
    const { speciesId, id, displayName, distributorId } = params;
    return new Seedlings({
      speciesId,
      distributorId,
      name: {
        kanji: id,
        kana: id,
      },
      productName: {
        kanji: displayName,
        kana: displayName,
      },
      registrationStateKey: REGISTRATION_STATE.NOT_RESEARCHED.KEY,
      reviewCount: 0,
      onSale: true,
      image: {
        imageUrl: null,
      },
      registerFrom: 'manually',
    });
  }

  constructor(props: SeedlingsBase) {
    super(props);
  }
}
