import { Entity } from '@common/domain/entity/common';
import * as fs from 'firebase/firestore';
import { FsAppManager } from '@fs/manager/app';
import { RefValue } from '@frontend/support/type';
import '@frontend/repository/firebase';
import { MayBeArray, toArray } from '@common/support/array';

export namespace FSAppRepository {
  type DocumentSnapshot<T> = fs.DocumentSnapshot<T>;
  type QuerySnapshot<T> = fs.QuerySnapshot<T>;
  type CollectionReference<T extends Entity> = fs.CollectionReference<T>;

  const db = () => fs.getFirestore();

  export const getCollection = <T extends Entity>(
    manager: FsAppManager<T>,
  ): CollectionReference<T> => {
    const collection = fs.collection(db(), manager.path);
    return collection.withConverter<T>(manager.converter);
  };

  export const querySnapshotToRefValues = <T>(snapshot: QuerySnapshot<T>) => {
    const refValue: RefValue<T>[] = [];

    let lastDocSnapshot: DocumentSnapshot<unknown> | undefined = undefined;
    snapshot.forEach(item => {
      lastDocSnapshot = item;
      refValue.push({
        ref: item.ref,
        value: item.data(),
      });
    });
    return { refValue, lastDocSnapshot };
  };

  export const documentSnapshotToRefValue = <T>(
    snapshot: DocumentSnapshot<T>,
  ): RefValue<T> => {
    return {
      ref: snapshot.ref,
      value: snapshot.data() as T,
    };
  };

  type GetItemsOptions = {
    limit?: number;
    offset?: number;
    wheres?: fs.QueryConstraint[];
    orderBy?: MayBeArray<{ key: string; dir: 'asc' | 'desc' }>;
    startAfter?: fs.DocumentSnapshot<unknown>;
  };
  export const getItems = async <T extends Entity>(
    manager: FsAppManager<T>,
    { limit, wheres, orderBy, startAfter }: GetItemsOptions,
  ) => {
    const collection = getCollection<T>(manager);

    const conditions: fs.QueryConstraint[] = [];

    if (orderBy) {
      for (const { key, dir } of toArray(orderBy)) {
        conditions.push(fs.orderBy(key, dir));
      }
    }
    if (startAfter) conditions.push(fs.startAfter(startAfter));
    if (limit) conditions.push(fs.limit(limit));

    const query = fs.query(collection, ...(wheres || []), ...conditions);
    const res = querySnapshotToRefValues(await fs.getDocs(query));
    return {
      items: res.refValue,
      lastDocSnapshot: res.lastDocSnapshot,
    };
  };

  export const getItem = async <T extends Entity>(
    manager: FsAppManager<T>,
    id: T['id'],
  ): Promise<RefValue<T>> => {
    const collection = getCollection(manager);
    const doc = fs.doc(collection, id);
    const res = await fs.getDoc(doc);
    return documentSnapshotToRefValue(res);
  };

  export const count = async <T extends Entity>(manager: FsAppManager<T>) => {
    const collection = getCollection(manager);
    const res = await fs.getDocs(collection);
    return res.size;
  };

  export const addItem = async <T extends Entity>(
    manager: FsAppManager<T>,
    entity: T,
  ) => {
    const collection = getCollection(manager);
    await fs.addDoc(collection, entity);
  };

  export const addItemWithId = async <T extends Entity>(
    manager: FsAppManager<T>,
    entity: T,
  ) => {
    const collection = getCollection(manager);
    const doc = fs.doc(collection, entity.id);
    await fs.setDoc(doc, entity);
  };

  export const isExist = async <T extends Entity>(
    manager: FsAppManager<T>,
    id: string,
  ) => {
    const collection = getCollection(manager);
    const doc = fs.doc(collection, id);
    const item = await fs.getDoc(doc);
    return item.exists();
  };

  export const update = async <T extends Entity>(
    manager: FsAppManager<T>,
    id: string,
    attrs: fs.UpdateData<T>,
  ) => {
    const collection = getCollection(manager);
    const doc = fs.doc(collection, id);
    await fs.updateDoc(doc, attrs);
  };

  export const deleteItem = async <T extends Entity>(
    manager: FsAppManager<T>,
    id: T['id'],
  ) => {
    const collection = getCollection(manager);
    const doc = fs.doc(collection, id);
    await fs.deleteDoc(doc);
  };

  export const listenById = <T extends Entity>(
    manager: FsAppManager<T>,
    id: T['id'],
    onListen: (value: RefValue<T>) => void,
  ): { unsubscribe: () => void } => {
    const collection = getCollection(manager);
    const doc = fs.doc(collection, id);
    const unsubscribe = fs.onSnapshot(doc, docSnapshot => {
      onListen(documentSnapshotToRefValue(docSnapshot));
    });
    return { unsubscribe };
  };
}
