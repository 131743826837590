import { PartialBy, ValueOf } from '../../types/type';
import { Opaque } from 'type-fest';
import { Entity } from '@common/domain/entity/common';
import { ImageAttrs } from '@common/domain/valueObject/imageAttrs';

export const CROP_CLASS = {
  VEGETABLES: {
    KEY: 'vegetables',
    LABEL: '野菜',
    IMG: '/images/unsplash/vegetables.jpg',
  },
  FRUITS: { KEY: 'fruits', LABEL: '果樹', IMG: '/images/unsplash/fruits.jpg' },
} as const;
export type CropClassKey = ValueOf<typeof CROP_CLASS>['KEY'];
export type CropClassLabel = ValueOf<typeof CROP_CLASS>['LABEL'];

type SpeciesBaseCostractPrams = PartialBy<SpeciesBase, 'displayName'>;

// Species of plant
class SpeciesBase {
  // 作物区分
  readonly cropClass: CropClassKey;

  // 植物の種類名
  readonly name: string; // 'スイカ'
  readonly displayName: string; // 'スイカ'
  // 流通データベース上での名前
  readonly nameOnDb?: string; // 'スイカ'

  // 植物のイメージ画像
  readonly image?: ImageAttrs;

  // 品種数
  readonly seedlingsCount: number;

  // レビュー総数
  readonly reviewCount: number;

  protected constructor(props: SpeciesBaseCostractPrams) {
    this.cropClass = props.cropClass;
    this.name = props.name;
    this.displayName = props.displayName || this.name;
    this.nameOnDb = props.nameOnDb;
    this.image = props.image;
    this.seedlingsCount = props.seedlingsCount || 0;
    this.reviewCount = props.reviewCount || 0;
  }
}

export type SpeciesId = Opaque<string, 'SpeciesId'>;

export class Species extends SpeciesBase implements Entity {
  static generateId(cropClass: CropClassKey, name: string) {
    return [cropClass, name].join('-') as SpeciesId;
  }

  get id() {
    return Species.generateId(this.cropClass, this.name);
  }

  constructor(props: SpeciesBaseCostractPrams) {
    super(props);
  }
}
