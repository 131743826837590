import React from 'react';
import cn from 'classnames';
import { MyPieChart } from '@frontend/components/atoms/MyPieChart';
import {
  getSoilComponentLabel,
  SoilComponentItem,
} from '@common/domain/entity/review/consts';

export type SoilComponentPieChartProps = {
  className?: string;
  value: SoilComponentItem[];
};

export const SoilComponentPieChart: React.FC<SoilComponentPieChartProps> = props => {
  const { className, value } = props;

  return (
    <MyPieChart
      className={cn('SoilComponentPieChart', className)}
      title="用土"
      data={value.map(v => ({
        label: getSoilComponentLabel(v.name),
        value: v.ratio,
      }))}
    />
  );
};
