import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { PageTitle } from '@frontend/components/atoms/PageTitle';
import { useController, useForm } from 'react-hook-form';
import { useAuthState } from '@frontend/store/auth/action';
import { InputGroup } from '@chakra-ui/react';
import { MyInput } from '@frontend/components/atoms/MyInput';
import {
  labeledEnumConstToItem,
  SegmentButton,
} from '@frontend/components/atoms/SegmentButton';
import { MyButton } from '@frontend/components/atoms/MyButton';
import { FsAppManager } from '@fs/manager/app';
import { FSAppRepository } from '@frontend/repository/firestore';
import { GoBackLink } from '@frontend/components/atoms/GoBackLink';
import { Routing } from '@frontend/settings/routing';
import { User } from '@common/domain/entity/user';
import { LoadingBlockModal } from '@frontend/components/molecules/LoadingBlockModal';
import { useMyToast } from '@frontend/repository/ui';
import { AREA, AreaKey, PURPOSE, PurposeKey } from '@common/domain/entity/review/consts';

export type UserSettingsPageProps = {
  className?: string;
};

type Inputs = {
  name: string;
  defaultPurpose: PurposeKey;
  defaultArea: AreaKey;
};

const manager = new FsAppManager.User();
export const UserSettingsPage: React.FC<UserSettingsPageProps> = props => {
  const { className } = props;
  const { user, setUser } = useAuthState();
  const toTop = Routing.Params.Top.useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const { toast } = useMyToast();

  const { control, getValues, formState, reset } = useForm<Inputs>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      defaultPurpose: 'kitchen',
      defaultArea: 'un-selected',
    },
  });

  const resetWithUser = (user: User) => {
    const values: Inputs = {
      name: user.name,
      defaultPurpose: user.defaultPurpose,
      defaultArea: user.defaultArea,
    };
    reset(values);
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        resetWithUser(user);
      }, 0);
    }
  }, [user?.id]);

  const name = useController({
    control,
    name: 'name',
    rules: { required: '必須です。' },
  });

  const save = async () => {
    if (!user) return;
    setIsSaving(true);
    try {
      const { name, defaultPurpose, defaultArea } = getValues();
      await FSAppRepository.update(manager, user.id, {
        name: name,
        defaultPurpose,
        defaultArea,
      });
      const newUser = (await FSAppRepository.getItem(manager, user.id)).value;
      setUser(newUser);
      resetWithUser(newUser);
      toast('保存しました！');
    } finally {
      setIsSaving(false);
    }
  };

  const canSave = !!user && formState.isValid && formState.isDirty;

  return (
    <div className={cn('UserSettingsPage', className)}>
      <div className="Section">
        <GoBackLink navigator={toTop} />
      </div>
      <div className="Content">
        <PageTitle title="ユーザ設定" />

        <div className="Section">
          <InputGroup size="md" variant="filled">
            <MyInput controller={name} placeholder="ユーザ名" disabled={!user} />
            <SegmentButton
              key="defaultPurpose"
              name="defaultPurpose"
              label={PURPOSE.LABEL}
              options={PURPOSE.ITEMS.map(labeledEnumConstToItem)}
              control={control}
              disabled={!user}
            />
            <SegmentButton
              key="defaultArea"
              name="defaultArea"
              label={AREA.LABEL}
              options={AREA.ITEMS.map(labeledEnumConstToItem)}
              control={control}
              disabled={!user}
            />
          </InputGroup>
        </div>

        <div className="Section">
          <MyButton title="保存" onClick={save} active={canSave} isLoading={isSaving} />
        </div>
        <LoadingBlockModal isLoading={!user} />
      </div>
    </div>
  );
};
