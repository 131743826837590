import { useRecoilState } from 'recoil';
import { AUTH_STATE } from '@frontend/store/auth/atom';
import { User, UserId } from '@common/domain/entity/user';
import { User as AuthUser } from 'firebase/auth';
import { UserAppRepository } from '@frontend/repository/firestore/user';

let unsubscribe: null | (() => void) = null;
export const useAuthState = () => {
  const [state, setState] = useRecoilState(AUTH_STATE);

  const isAuthed = !!state.user?.name;

  const setUser = (user?: User) => {
    setState(pre => {
      return {
        ...pre,
        user,
      };
    });
  };

  const authStateChangeSubscriber = async (authUser: AuthUser | null) => {
    console.log('auth status change', { authUser });

    if (!authUser) {
      // detect sign out
      unsubscribe?.();
      setUser(undefined);
      return;
    }

    // detect sign in
    const res = UserAppRepository.listenById(authUser.uid as UserId, user => {
      console.log('listen user data');
      setUser(user.value);
    });
    unsubscribe = res.unsubscribe;
  };

  return {
    user: state.user,
    setUser: setUser,
    isAuthed,
    authStateChangeSubscriber,
  };
};
