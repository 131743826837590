import React from 'react';
import cn from 'classnames';
import { ReviewCard } from '@frontend/components/molecules/ReviewCard';
import { useFs } from '@frontend/hooks/firestore';
import { SpeciesId } from '@common/domain/valueObject/species';
import { SeedlingsId } from '@common/domain/entity/seedlings';
import { useAuthState } from '@frontend/store/auth/action';
import { useMasterState } from '@frontend/store/master/action';
import { ReviewAppRepository } from '@frontend/repository/firestore/review';
import { User, UserId } from '@common/domain/entity/user';
import { Skeleton } from '@chakra-ui/react';
import { ReadMoreWrapper } from '@frontend/components/molecules/ReadMoreWrapper';
import { useUiState } from '@frontend/store/ui/action';
import { useMyToast } from '@frontend/repository/ui';
import { Review, ReviewId } from '@common/domain/entity/review';
import { StorageHooks } from '@frontend/hooks/storage';

export type ReviewCardListProps = {
  className?: string;
  speciesId: SpeciesId;
  seedlingsId: SeedlingsId;
};

const renderCards = (props: {
  reviews: Review[] | undefined;
  users: User[];
  authUser: User | undefined;
  onDelete: (id: ReviewId) => void;
  onGood: (id: ReviewId, createUserId: UserId) => void;
  listIndex: number;
}) => {
  const { reviews, users, authUser, onDelete, listIndex, onGood } = props;
  return (
    <Skeleton
      key={`${listIndex}`}
      isLoaded={!!reviews}
      fadeDuration={0.8}
      minHeight={400}
    >
      {reviews?.map((review, index) => {
        const user = users.find(u => u.id === review.userId);
        return (
          <ReviewCard
            key={`${listIndex}-${index}`}
            review={review}
            createUser={user}
            authUser={authUser}
            onDelete={() => onDelete(review.id)}
            onGood={createUserId => onGood(review.id, createUserId)}
          />
        );
      })}
    </Skeleton>
  );
};

export const ReviewCardList: React.FC<ReviewCardListProps> = props => {
  const { className, speciesId, seedlingsId } = props;

  const { user: authUser } = useAuthState();
  const { fetchIfNotFetched, users } = useMasterState();
  const { openAuthBlockModal } = useUiState();

  const { toast } = useMyToast();

  const useReviewResponse = useFs.Review.list({
    speciesId,
    seedlingsId,
    fetchIfNotFetched,
  });
  const { itemList, resetPage, patchData } = useReviewResponse;

  const imageDeleter = StorageHooks.Review.useDelete();
  const deleteReview = async (reviewId: ReviewId) => {
    try {
      const res = await ReviewAppRepository.deleteItem({
        speciesId,
        seedlingsId,
        reviewId,
      });
      console.log('>>> delete images of the review');
      if (res?.deletedReview) {
        const { userId, createdAt } = res.deletedReview.value;
        await imageDeleter({
          userId,
          createdAt,
        });
      }

      await resetPage();
      toast('レビューを削除しました。');
    } finally {
      // setIsLoading(false);
    }
  };

  const addGoodToReview = async (reviewId: ReviewId, createUserId: UserId) => {
    try {
      const byUserId = authUser?.id;
      if (!byUserId) {
        openAuthBlockModal('参考になる！でレビューを応援する');
        return;
      }
      if (byUserId === createUserId) return;

      const res = await ReviewAppRepository.good({
        speciesId,
        seedlingsId,
        reviewId,
        byUserId,
      });

      if (res === 'error') throw new Error('failed to good');

      const message =
        res.type === 'add'
          ? '参考になる！をしました👍'
          : '参考になる！ を取り消しました。';

      // update local review object
      patchData(reviewId, 'good', res.newGood);

      toast(message);
    } finally {
      // do
    }
  };

  return (
    <ReadMoreWrapper
      className={cn('ReviewCardList', className)}
      useReadMoreResponse={useReviewResponse}
    >
      {itemList.map((list, index) => {
        return renderCards({
          reviews: list?.map(i => i.value),
          users,
          authUser,
          onDelete: deleteReview,
          listIndex: index,
          onGood: addGoodToReview,
        });
      })}
    </ReadMoreWrapper>
  );
};
