import { useRecoilState } from 'recoil';
import { User, UserId } from '@common/domain/entity/user';
import { MASTER_STATE } from '@frontend/store/master/atom';
import { FsAppManager } from '@fs/manager/app';
import { FSAppRepository } from '@frontend/repository/firestore';
import * as fs from 'firebase/firestore';
import { uniqueArray } from '@common/support/array';

export type UseMasterStateResponse = {
  users: User[];
  setUsers: (users: User[]) => void;
  fetchIfNotFetched: (userIds: UserId[]) => Promise<void>;
};

const userManager = new FsAppManager.User();

export const useMasterState = (): UseMasterStateResponse => {
  const [state, setState] = useRecoilState(MASTER_STATE);

  const setUsers = (users: User[]) => {
    setState(pre => {
      return {
        ...pre,
        users,
      };
    });
  };

  const fetchIfNotFetched = async (userIds: UserId[]): Promise<void> => {
    const uniqueUserIds = uniqueArray(userIds);
    const existingUserIds = state.users.map(u => u.id);
    const notFetchedUserIds = uniqueUserIds.filter(id => !existingUserIds.includes(id));
    if (!notFetchedUserIds.length) return;
    const res = await FSAppRepository.getItems(userManager, {
      wheres: [fs.where(fs.documentId(), 'in', notFetchedUserIds)],
    });
    const fetchedUsers = res.items.map(u => u.value);
    setUsers(fetchedUsers);
    console.log('user fetched', fetchedUsers);
  };

  return {
    setUsers,
    users: state.users,
    fetchIfNotFetched,
  };
};
