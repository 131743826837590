import { SpeciesId } from '@common/domain/valueObject/species';
import { Seedlings, SeedlingsId } from '@common/domain/entity/seedlings';
import { useEffect, useState } from 'react';
import { FsAppManager } from '@fs/manager/app';
import { FSAppRepository } from '@frontend/repository/firestore';

const createKey = (speciesId: SpeciesId, seedlingsId: SeedlingsId) => {
  return [speciesId, seedlingsId].join();
};

export type UseFs_Seedlings_ByIdRes = {
  key: string;
  item?: Seedlings;
  unsubscribe?: () => void;
  isLoading: boolean;
};
const BY_ID_DEFAULT = { key: '', isLoading: false };

export const useFs_Seedlings_ById = (speciesId: SpeciesId, seedlingsId: SeedlingsId) => {
  const [condition, setCondition] = useState<UseFs_Seedlings_ByIdRes>(BY_ID_DEFAULT);

  useEffect(() => {
    if (!speciesId || !seedlingsId) return;
    const manager = new FsAppManager.Seedlings(speciesId);
    const res = FSAppRepository.listenById(manager, seedlingsId, value => {
      setCondition(pre => {
        return {
          ...pre,
          isLoading: false,
          item: value.value,
        };
      });
    });
    setCondition({
      key: createKey(speciesId, seedlingsId),
      unsubscribe: res.unsubscribe,
      isLoading: true,
    });
  }, [speciesId, seedlingsId]);

  return {
    seedlings: condition.item,
    isFetching: condition.isLoading,
  };
};
