import React from 'react';
import { GoBackLink } from '@frontend/components/atoms/GoBackLink';
import { SectionDivider } from '@frontend/components/atoms/SectionDivider';
import { SpeciesCardList } from '@frontend/components/organisms/SpeciesCardList';
import { CROP_CLASS, CropClassKey } from '@common/domain/valueObject/species';
import { Routing } from '@frontend/settings/routing';
import classNames from 'classnames';
import { MyChip } from '@frontend/components/atoms/MyChip';
import { ReadMoreWrapper } from '@frontend/components/molecules/ReadMoreWrapper';
import { useFs } from '@frontend/hooks/firestore';
import { MyHead } from '@frontend/components/common/Head';

export type SpeciesProps = {};

const toggleSelectedCropClassKey = (key: CropClassKey, selected: CropClassKey[]) => {
  return selected.includes(key) ? selected.filter(i => i !== key) : [...selected, key];
};

const Controls: React.FC<{
  selected: CropClassKey[];
  className: string;
  onClickCallback: () => void;
}> = props => {
  const { selected, className, onClickCallback } = props;
  const navigate = Routing.Params.Species.useNavigate();
  return (
    <div className={classNames('ControlsContainer', className)}>
      絞り込み:
      {Object.values(CROP_CLASS).map(({ KEY, LABEL }) => {
        const onClickHandler = () => {
          onClickCallback();
          navigate(toggleSelectedCropClassKey(KEY, selected));
        };
        return (
          <MyChip
            key={KEY}
            title={LABEL}
            onClick={onClickHandler}
            isActive={selected.includes(KEY)}
          />
        );
      })}
    </div>
  );
};

export const SpeciesPage: React.FC<SpeciesProps> = () => {
  const selectedCropClasses = Routing.Params.Species.useParams().cropClasses;

  const useSpeciesResponse = useFs.Species.list(selectedCropClasses);

  const cropClassLabels = selectedCropClasses
    .map(key => {
      const keyUpperCase = key.toUpperCase() as Uppercase<CropClassKey>;
      return CROP_CLASS[keyUpperCase].LABEL;
    })
    .join(', ');
  const ogImage = selectedCropClasses.includes(CROP_CLASS.VEGETABLES.KEY)
    ? CROP_CLASS.VEGETABLES.IMG
    : CROP_CLASS.FRUITS.IMG;

  return (
    <div className="SpeciesPage">
      <MyHead pageTitle={`「${cropClassLabels}」の作物一覧`} ogImage={ogImage} />
      <GoBackLink linkUrl="/" />

      <SectionDivider title="作物の種類一覧" type="species" />

      <Controls
        className="Section"
        selected={selectedCropClasses}
        onClickCallback={useSpeciesResponse.resetPage}
      />

      <ReadMoreWrapper className="Section" useReadMoreResponse={useSpeciesResponse}>
        <SpeciesCardList itemList={useSpeciesResponse.itemList} />
      </ReadMoreWrapper>
    </div>
  );
};
