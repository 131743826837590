import React from 'react';
import { Species, SpeciesId } from '@common/domain/valueObject/species';
import { MyCard } from '@frontend/components/molecules/MyCard';
import cn from 'classnames';
import { Routing } from '@frontend/settings/routing';
import { RefValue } from '@frontend/support/type';
import { Skeleton } from '@chakra-ui/react';

export type SpeciesCardListProps = {
  className?: string;
  itemList: (RefValue<Species>[] | undefined)[];
};

const imgUrl = '/images/unsplash/vegetables.jpg';

const renderCards = (
  navigator: (speciesId: SpeciesId) => void,
  items: RefValue<Species>[] | undefined,
  listIndex: number,
) => {
  return (items || Array(12).fill(undefined)).map((_item, index) => {
    const item = _item?.value as Species | undefined;
    return (
      <Skeleton
        className="SpeciesCard Skeleton"
        key={`${listIndex}-${index}`}
        isLoaded={!!item}
        fadeDuration={0.8}
      >
        <MyCard
          title={item?.displayName}
          src={(item?.image ? item.image.imageUrl : imgUrl) || undefined}
          imageReferrer={
            !item?.image?.referenceUrl
              ? undefined
              : {
                  url: item?.image?.imageUrl || undefined,
                  name: item?.image?.referenceName || undefined,
                }
          }
          seedlingsCount={item?.seedlingsCount}
          onClick={() => item?.id && navigator(item.id)}
        />
      </Skeleton>
    );
  });
};

export const SpeciesCardList: React.FC<SpeciesCardListProps> = props => {
  const { className, itemList } = props;
  const navigator = Routing.Params.Seedlings.useNavigate();
  return (
    <div className={cn('SpeciesCardList', className)}>
      <div className="Items">
        {itemList.map((list, index) => {
          return renderCards(navigator, list, index);
        })}
      </div>
    </div>
  );
};
