import { ValueOf } from 'type-fest';
import { TopPage } from '@frontend/components/pages/Top';
import { SpeciesPage } from '@frontend/components/pages/Species';
import { CROP_CLASS, CropClassKey, SpeciesId } from '@common/domain/valueObject/species';
import { SeedlingsPage } from '@frontend/components/pages/Seedlings';
import {
  createSearchParams,
  URLSearchParamsInit,
  useNavigate as _useNavigate,
  useSearchParams as _useSearchParams,
} from 'react-router-dom';
import { ErrorPage } from '@frontend/components/pages/Error';
import { ReviewPage } from '@frontend/components/pages/Review';
import { CreateReviewPage } from '@frontend/components/pages/CreateReview';
import { SeedlingsId } from '@common/domain/entity/seedlings';
import { LoginPage } from '@frontend/components/pages/Login';
import { UserSettingsPage } from '@frontend/components/pages/UserSettings';
import { AdminPage } from '@frontend/components/pages/Admin';
import { AboutPage } from '@frontend/components/pages/AboutPage';

export namespace Routing {
  export const ITEMS = {
    ADMIN: {
      PATH: '/admin',
      NAME: 'admin',
      LABEL: '管理画面',
      COMPONENT: AdminPage,
    },
    ABOUT: {
      PATH: '/about',
      NAME: 'about',
      LABEL: 'タネログとは？',
      COMPONENT: AboutPage,
    },
    USER_SETTINGS: {
      PATH: '/user-settings',
      NAME: 'user-settings',
      LABEL: 'ユーザ設定',
      COMPONENT: UserSettingsPage,
    },
    CREATE_REVIEW: {
      PATH: '/review/create',
      NAME: 'create-review',
      LABEL: 'レビュー作成',
      COMPONENT: CreateReviewPage,
    },
    REVIEWS: {
      PATH: '/review',
      NAME: 'review-list',
      LABEL: 'レビュー一覧',
      COMPONENT: ReviewPage,
    },
    SEEDLINGS: {
      PATH: '/seedlings',
      NAME: 'seedlings-list',
      LABEL: '品種一覧',
      COMPONENT: SeedlingsPage,
    },
    SPECIES: {
      PATH: '/species',
      NAME: 'species-list',
      LABEL: '作物の種類一覧',
      COMPONENT: SpeciesPage,
    },
    LOGIN: {
      PATH: '/login',
      NAME: 'login',
      LABEL: 'ログイン',
      COMPONENT: LoginPage,
    },
    TOP: {
      PATH: '/',
      NAME: 'top',
      LABEL: 'トップ',
      COMPONENT: TopPage,
    },
    ERROR: {
      PATH: '/error',
      NAME: 'error',
      COMPONENT: ErrorPage,
    },
  } as const;

  export type Item = ValueOf<typeof ITEMS>;
  export type Path = Item['PATH'];
  export type Names = Item['NAME'];

  export namespace Params {
    namespace _common {
      export const useSearchParams = () => {
        const [params] = _useSearchParams();
        return params;
      };
      export const useNavigate = () => {
        const navigator = _useNavigate();
        return (path: string, searchParams?: URLSearchParamsInit) => {
          let url = path;
          if (searchParams) {
            url += '?' + createSearchParams(searchParams).toString();
          }
          navigator(url);
        };
      };
      export const parse = (oldQueryString: URLSearchParams) => {
        return Object.fromEntries(new URLSearchParams(oldQueryString).entries());
      };
    }

    export namespace Top {
      export type Type = {};
      export type Query = {};
      const SYMBOL_MAP: { [key in keyof Type]: keyof Query } = {};

      export const useParams = (): Type => {
        return {};
      };

      export const useNavigate = () => {
        const navigator = _common.useNavigate();
        return () => {
          navigator(ITEMS.TOP.PATH);
        };
      };
    }

    export namespace Species {
      export type Type = { cropClasses: CropClassKey[] };
      export type Query = { c: string };
      const SYMBOL_MAP: { [key in keyof Type]: keyof Query } = { cropClasses: 'c' };

      const cropClassKeys = Object.values(CROP_CLASS).map(c => c.KEY);

      export const useParams = (): Type => {
        const params = _common.useSearchParams();
        const valueString = params.get(SYMBOL_MAP.cropClasses) || '';
        const valueArray = valueString
          .split(',')
          .filter(i => cropClassKeys.includes(i as any)) as Type['cropClasses'];
        return {
          cropClasses: valueArray.length ? valueArray : [CROP_CLASS.VEGETABLES.KEY],
        };
      };

      export const useNavigate = () => {
        const navigator = _common.useNavigate();
        return (keys: CropClassKey[]) => {
          if (!keys.length) return;
          const params: Query = { c: keys.join() };
          navigator(ITEMS.SPECIES.PATH, params);
        };
      };
    }

    export namespace Seedlings {
      export type Type = { speciesId: SpeciesId; searchString: string | null };
      export type Query = { spid: string; s?: string };
      const SYMBOL_MAP: { [key in keyof Type]: keyof Query } = {
        speciesId: 'spid',
        searchString: 's',
      };

      export const useParams = (): Type => {
        const params = _common.useSearchParams();
        const speciesId = params.get(SYMBOL_MAP.speciesId);
        const searchString = params.get(SYMBOL_MAP.searchString);
        if (!speciesId) throw new Error('speciesId is not defined');
        return {
          speciesId: speciesId as SpeciesId,
          searchString,
        };
      };

      export const useNavigate = () => {
        const navigator = _common.useNavigate();
        return (speciesId: SpeciesId, searchString?: string) => {
          const params: Query = {
            spid: speciesId,
            ...(searchString ? { s: searchString } : {}),
          };
          navigator(ITEMS.SEEDLINGS.PATH, params);
        };
      };
    }

    export namespace Reviews {
      export type Type = { speciesId: SpeciesId; seedlingsId: SeedlingsId };
      export type Query = { seid: string; spid: string };
      const SYMBOL_MAP: { [key in keyof Type]: keyof Query } = {
        speciesId: 'spid',
        seedlingsId: 'seid',
      };

      export const useParams = (): Type => {
        const params = _common.useSearchParams();
        const speciesId = params.get(SYMBOL_MAP.speciesId);
        const seedlingsId = params.get(SYMBOL_MAP.seedlingsId);
        if (!speciesId || !seedlingsId)
          throw new Error('species or seedlingsId is not defined');
        return {
          speciesId: speciesId as SpeciesId,
          seedlingsId: seedlingsId as SeedlingsId,
        };
      };

      export const useNavigate = () => {
        const navigator = _common.useNavigate();
        return (speciesId: SpeciesId, seedlingsId: SeedlingsId) => {
          const query: Query = { spid: speciesId, seid: seedlingsId };
          navigator(ITEMS.REVIEWS.PATH, query);
        };
      };
    }

    export namespace ReviewsCreate {
      export type Type = { speciesId: SpeciesId; seedlingsId: SeedlingsId };
      export type Query = { seid: string; spid: string };
      const SYMBOL_MAP: { [key in keyof Type]: keyof Query } = {
        speciesId: 'spid',
        seedlingsId: 'seid',
      };

      export const useParams = (): Type => {
        const params = _common.useSearchParams();
        const speciesId = params.get(SYMBOL_MAP.speciesId);
        const seedlingsId = params.get(SYMBOL_MAP.seedlingsId);
        if (!speciesId || !seedlingsId)
          throw new Error('species or seedlingsId is not defined');
        return {
          speciesId: speciesId as SpeciesId,
          seedlingsId: seedlingsId as SeedlingsId,
        };
      };

      export const useNavigate = () => {
        const navigator = _common.useNavigate();
        return (speciesId: SpeciesId, seedlingsId: SeedlingsId) => {
          const query: Query = { spid: speciesId, seid: seedlingsId };
          navigator(ITEMS.CREATE_REVIEW.PATH, query);
        };
      };
    }

    export namespace Login {
      export type Type = {};
      export type Query = {};
      const SYMBOL_MAP: { [key in keyof Type]: keyof Query } = {};

      export const useParams = (): Type => {
        return {};
      };

      export const useNavigate = () => {
        const navigator = _common.useNavigate();
        return () => {
          navigator(ITEMS.LOGIN.PATH);
        };
      };
    }

    export namespace About {
      export type Type = {};
      export type Query = {};
      const SYMBOL_MAP: { [key in keyof Type]: keyof Query } = {};

      export const useParams = (): Type => {
        return {};
      };

      export const useNavigate = () => {
        const navigator = _common.useNavigate();
        return () => {
          navigator(ITEMS.ABOUT.PATH);
        };
      };
    }

    export namespace UserSettings {
      export type Type = {};
      export type Query = {};
      const SYMBOL_MAP: { [key in keyof Type]: keyof Query } = {};

      export const useParams = (): Type => {
        return {};
      };

      export const useNavigate = () => {
        const navigator = _common.useNavigate();
        return () => {
          navigator(ITEMS.USER_SETTINGS.PATH);
        };
      };
    }
  }
}
