import React from 'react';
import cn from 'classnames';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';

export type MyAccordionProps = {
  className?: string;
  children: React.ReactNode;
  onChange?: (isExpanded: boolean) => void;
};

export const MyAccordion: React.FC<MyAccordionProps> = props => {
  const { className, children, onChange } = props;

  const onChangeHandler = (_index: number) => {
    if (!onChange) return;
    const index = _index as 0 | -1;
    onChange(index === 0);
  };

  return (
    <Accordion
      allowToggle
      className={cn('MyAccordion', className)}
      onChange={onChangeHandler}
    >
      <AccordionItem>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <AccordionIcon /> 詳細を入力する
          </Box>
        </AccordionButton>
        <AccordionPanel pb={4}>{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
