import React from 'react';
import { MyCardProps } from '@frontend/components/molecules/MyCard';
import { Seedlings, SeedlingsId } from '@common/domain/entity/seedlings';
import { Box, Icon, Image, Skeleton } from '@chakra-ui/react';
import { MdRateReview, MdChat } from 'react-icons/md';
import { getOrganizationByName } from '@common/master/organizations';
import cn from 'classnames';
import { IMAGES } from '@frontend/support/image';

type SeedlingsCardType = 'list' | 'pickup';
export type SeedlingsCardProps = {
  className?: string;
  seedlings: Seedlings | undefined;
  navigator?: (seedlingsId: SeedlingsId) => void;
  type: SeedlingsCardType;
};

const renderImage = (src: string | undefined) => {
  if (!src) return <Image className="No" src={IMAGES.NO} />;
  return <Image src={src} />;
};

const renderImageReferrer = (image?: Seedlings['image']) => {
  const src = image?.imageUrl || '';
  const imageReferrer = image && {
    name: image.referenceName,
    url: image.referenceUrl,
  };
  return (
    <>
      {src && imageReferrer && (
        <div
          className="ImageReferrer"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <a href={imageReferrer.url} target="_blank" rel="noreferrer">
            画像: {imageReferrer.name}
          </a>
        </div>
      )}
    </>
  );
};

export const SeedlingsCard: React.FC<SeedlingsCardProps> = props => {
  const { className, seedlings, navigator, type } = props;

  const reviewCount = seedlings?.reviewCount;
  const title = seedlings?.displayName || '不明';
  const src = seedlings?.image?.imageUrl || '';

  const onClick = () => seedlings?.id && navigator?.(seedlings.id);
  const subtitle = getOrganizationByName(seedlings?.distributorId)?.name;

  return (
    <Skeleton
      className={cn('SeedlingsCard', type === 'pickup' && 'Pickup')}
      isLoaded={!!seedlings}
      fadeDuration={0.8}
    >
      {/* スケルトンの高さを維持するため */}
      {!seedlings && <div style={{ height: 300 }} />}

      <Box boxShadow="xl" onClick={onClick} className="Content" role="link">
        <div className="ImageContainer">{renderImage(src)}</div>

        <div className="Details">
          {renderImageReferrer(seedlings?.image)}

          {!src && <p className="Title">{title}</p>}
          <div className="ReviewCount">
            <Icon as={MdChat} />
            レビュー: {reviewCount}
          </div>
        </div>
      </Box>
    </Skeleton>
  );
};
