import React, { useState } from 'react';
import cn from 'classnames';
import { PageTitle } from '@frontend/components/atoms/PageTitle';
import { Routing } from '@frontend/settings/routing';
import { SeedlingsCard } from '@frontend/components/atoms/SeedlingsCard';
import { ReviewInput } from '@frontend/components/molecules/ReviewInput';
import { useAuthState } from '@frontend/store/auth/action';
import { LoadingBlockModal } from '@frontend/components/molecules/LoadingBlockModal';
import { useMyToast } from '@frontend/repository/ui';
import { ReviewAppRepository } from '@frontend/repository/firestore/review';
import { useFs } from '@frontend/hooks/firestore';
import { Review, ReviewId } from '@common/domain/entity/review';
import { StorageHooks } from '@frontend/hooks/storage';
import { UserId } from '@common/domain/entity/user';

export type CreateReviewProps = {
  className?: string;
};

export const CreateReviewPage: React.FC<CreateReviewProps> = props => {
  const { className } = props;
  const { speciesId, seedlingsId } = Routing.Params.ReviewsCreate.useParams();
  const [isSaving, setIsSaving] = useState(false);
  const { toast } = useMyToast();
  const toReviewPage = Routing.Params.Reviews.useNavigate();

  const { user } = useAuthState();
  const { seedlings, isFetching } = useFs.Seedlings.byId(speciesId, seedlingsId);

  const imageUploader = StorageHooks.Review.useUploader();
  const uploadImages = async (files: File[], userId: UserId, createdAt: string) => {
    return await Promise.all(
      files.map((file, index) => {
        return imageUploader({
          file,
          index,
          userId,
          createdAt,
        });
      }),
    );
  };

  const createReview = async (review: Review, files: File[]) => {
    try {
      setIsSaving(true);

      const userId = user?.id;
      if (!userId) return;

      console.log('upload?');
      await uploadImages(files, userId, review.createdAt);

      await ReviewAppRepository.create({ review, speciesId, seedlingsId });

      // Go Back to Review Page
      await toReviewPage(speciesId, seedlingsId);

      toast('レビューを投稿しました！');
    } catch (e: any) {
      console.log(e);
      toast('レビューの投稿に失敗しました。', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className={cn('CreateReviewPage', className)}>
      <div>
        <PageTitle title="レビューを投稿する" />
      </div>
      <div className="Section Card">
        <SeedlingsCard seedlings={seedlings} type="pickup" />
      </div>
      <div className="Section Input">
        <ReviewInput onSubmit={createReview} seedlingsId={seedlingsId} />
      </div>
      <LoadingBlockModal isLoading={!user || isSaving || isFetching} />
    </div>
  );
};
