import React from 'react';
import classNames from 'classnames';
import { CROP_CLASS, CropClassKey } from '@common/domain/valueObject/species';
import { MyCard } from '@frontend/components/molecules/MyCard';
import { Routing } from '@frontend/settings/routing';

export type CategoryListProps = {
  className?: string;
};

export const CategoryList: React.FC<CategoryListProps> = props => {
  const { className } = props;
  const navigate = Routing.Params.Species.useNavigate();

  const toSpeciesPage = (classKey: CropClassKey) => {
    navigate([classKey]);
  };

  return (
    <div className={classNames('CategoryList', className)}>
      {Object.values(CROP_CLASS).map(({ KEY, LABEL, IMG }) => {
        return (
          <MyCard
            onClick={() => toSpeciesPage(KEY)}
            key={KEY}
            title={LABEL}
            src={IMG}
            seedlingsCount={100}
          />
        );
      })}
    </div>
  );
};
