import React from 'react';
import cn from 'classnames';
import { MyInput } from '@frontend/components/atoms/MyInput';
import { Path, UseControllerReturn } from 'react-hook-form';
import { Button, InputGroup, InputRightElement, useDisclosure } from '@chakra-ui/react';

export type PasswordInputProps<
  Inputs extends Record<string, any>,
  Name extends Path<Inputs>,
> = {
  controller: UseControllerReturn<Inputs, Name>;
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
};

export function PasswordInput<
  Inputs extends Record<string, any>,
  Name extends Path<Inputs>,
>(props: PasswordInputProps<Inputs, Name>) {
  const { className, controller, ...rest } = props;
  const { isOpen, onToggle } = useDisclosure();

  return (
    <InputGroup className={cn('PasswordInput', className)}>
      <MyInput controller={controller} type={isOpen ? 'text' : 'password'} {...rest} />
      <InputRightElement width="60px">
        <Button h="1.75rem" size="sm" onClick={onToggle} tabIndex={-1}>
          {isOpen ? '非表示' : '表示'}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}
