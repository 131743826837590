import {
  useFs_Review_list,
  UserReview_ListParams,
} from '@frontend/hooks/firestore/review/list';

export namespace useFs_Review {
  type ListParams = UserReview_ListParams;

  export const list = useFs_Review_list;
}
