import React from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import { RoutingContent } from '@frontend/components/common/Routing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Utils } from '@frontend/components/common/Utils';
import { AuthBlockModal } from '@frontend/components/molecules/AuthBlockModal';
import { Layout } from '@frontend/components/common/Layout';

const queryClient = new QueryClient();

export type DocumentProps = {
  children?: React.ReactElement;
};
export const Document: React.FC<DocumentProps> = () => {
  return (
    <Router>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <Utils />
          <Layout>
            <RoutingContent />
            <AuthBlockModal />
          </Layout>
        </QueryClientProvider>
      </RecoilRoot>
    </Router>
  );
};
