import React, { ChangeEvent, useRef, useState } from 'react';
import cn from 'classnames';
import { Button, Image, Icon } from '@chakra-ui/react';
import { BrowserRepository } from '@frontend/repository/browser';
import { Control, useController } from 'react-hook-form';
import { ReviewInputSchema } from '@frontend/components/molecules/ReviewInput';
import { MyAlert } from '@frontend/components/atoms/MyAlert';
import { MdImage, MdOutlineClose } from 'react-icons/md';

type Data = { file: File; dataUrl: string };
export type ReviewFileInputProps = {
  className?: string;
  control: Control<ReviewInputSchema, any>;
  imageCountLimit: number;
  setIsLoading: (bool: boolean) => void;
  isLoading: boolean;
};

export const ReviewFileInput: React.FC<ReviewFileInputProps> = props => {
  const { className, control, imageCountLimit, setIsLoading, isLoading } = props;
  const ref = useRef<HTMLInputElement | null>(null);

  const controller = useController({
    control,
    name: 'images',
    defaultValue: [],
    rules: {
      validate: (value: Data[]) => {
        if (value.length > imageCountLimit) {
          return `添付できる画像は${imageCountLimit}個までです。`;
        }
        return undefined;
      },
    },
  });
  const {
    field: { value, onChange },
  } = controller;

  const addButtonHandler = () => {
    ref.current?.click();
  };

  const onImageSelectHandler = async (e: ChangeEvent<HTMLInputElement | null>) => {
    setIsLoading(true);
    try {
      const files = Array.from(e.target.files || []);

      const compressed: Data[] = [];
      for (const file of files) {
        if (value.some(v => v.file.name === file.name)) continue;
        // load image with compression
        compressed.push(await BrowserRepository.Image.compressFileIfLarge(file));
        setTimeout(() => onChange([...value, ...compressed]));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteHandler = (file: File) => {
    onChange(value.filter(v => v.file.name !== file.name));
  };

  const errorMessage = (controller.fieldState.error?.message || '') as string;

  return (
    <div className={cn('ReviewFileInput', className)}>
      <input
        className="Input"
        onChange={onImageSelectHandler}
        type="file"
        ref={ref}
        multiple={true}
        accept="image/png, image/jpeg"
      />

      <div className="PreviewContainer">
        {value.map(({ dataUrl, file }) => {
          return (
            <div key={file.name} className="ImageContainer">
              <div className="Delete" onClick={() => onDeleteHandler(file)}>
                <Icon as={MdOutlineClose} />
              </div>
              <Image src={dataUrl} />
            </div>
          );
        })}
      </div>

      <MyAlert active={!!errorMessage} title={errorMessage} />

      <Button isLoading={isLoading} className={'AddButton'} onClick={addButtonHandler}>
        <Icon className="Icon" as={MdImage} />
        画像を追加する
      </Button>
    </div>
  );
};
