import React from 'react';
import cn from 'classnames';
import {
  Avatar,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { User, UserId } from '@common/domain/entity/user';
import { labeledEnumConstKeyToLabel } from '@common/support/labeledEnumConst';
import { toDisplayDate } from '@common/support/time';
import { PurposeBadge } from '@frontend/components/atoms/PurposeBadge';
import { MdMoreHoriz, MdThumbUp } from 'react-icons/md';
import { SoilComponentPieChart } from '@frontend/components/atoms/SoilComponentPieChart';
import {
  AREA,
  METHOD,
  PLANTING_ENVIRONMENT,
  PLANTING_PLACE,
  SEASON,
  SEEDLINGS_TYPE,
  UN_SELECTED,
} from '@common/domain/entity/review/consts';
import { Review } from '@common/domain/entity/review';
import { StorageRepository } from '@frontend/repository/storage';
import { StorageHooks } from '@frontend/hooks/storage';

export type ReviewCardProps = {
  className?: string;
  review: Review;
  createUser: User | undefined;
  authUser: User | undefined;
  onDelete: () => void;
  onGood: (createUserId: UserId) => void;
};

const getDetailLabels = (review: Review) => {
  const area = labeledEnumConstKeyToLabel(AREA, review.area);
  const season = labeledEnumConstKeyToLabel(SEASON, review.season);
  const seedlingsType = labeledEnumConstKeyToLabel(SEEDLINGS_TYPE, review.seedlingsType);
  const plantingPlace = labeledEnumConstKeyToLabel(PLANTING_PLACE, review.plantingPlace);
  const plantingEnvironment = labeledEnumConstKeyToLabel(
    PLANTING_ENVIRONMENT,
    review.plantingEnvironment,
  );
  const method = labeledEnumConstKeyToLabel(METHOD, review.method);

  return {
    area: {
      label: AREA.LABEL,
      valueLabel: area,
    },
    season: {
      label: SEASON.LABEL,
      valueLabel: season,
    },
    seedlingsType: {
      label: SEEDLINGS_TYPE.LABEL,
      valueLabel: seedlingsType,
    },
    plantingPlace: {
      label: PLANTING_PLACE.LABEL,
      valueLabel: plantingPlace,
    },
    plantingEnvironment: {
      label: PLANTING_ENVIRONMENT.LABEL,
      valueLabel: plantingEnvironment,
    },
    method: {
      label: METHOD.LABEL,
      valueLabel: method,
    },
  };
};

type DetailItemProps = {
  label: string;
  valueLabel: string;
};
const DetailItem: React.FC<DetailItemProps> = ({ label, valueLabel }) => {
  return (
    <span className="DetailItem">
      <span className="Label">{label}</span>:
      <span className="ValueLabel">{valueLabel}</span>
    </span>
  );
};

type AuthorMenuProps = { isMine: boolean; onDelete: () => void };
const AuthorMenu: React.FC<AuthorMenuProps> = props => {
  const { isMine, onDelete } = props;
  if (!isMine) return null;
  return (
    <div className="AuthorMenu">
      <Menu>
        <MenuButton>
          <Icon as={MdMoreHoriz} />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={onDelete}>削除</MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
};

export const ReviewCard: React.FC<ReviewCardProps> = props => {
  const { className, review, createUser, authUser, onDelete, onGood } = props;

  const createTime = toDisplayDate(review.createdAt);
  const detailLabels = getDetailLabels(review);

  const isEvaluatedReview = authUser?.goodReviews.includes(review.id);

  const isMine = authUser?.id === createUser?.id;

  const { imageUrls } = StorageHooks.Review.useImages(review);

  return (
    <div className={cn('ReviewCard', className)}>
      <div className="HeadArea">
        <Avatar />
        <div className="TitleContainer">
          <span className="UserName">{createUser?.name || '不明'} さん</span>
          <span className="CreateTime">{createTime}</span>
          <PurposeBadge className="Purpose" purpose={review.purpose} />
          <AuthorMenu isMine={isMine} onDelete={onDelete} />
        </div>
      </div>
      <div className="Details">
        {Object.values(detailLabels).map(({ label, valueLabel }) => {
          if (valueLabel === UN_SELECTED.LABEL) return null;
          return (
            valueLabel && (
              <DetailItem key={valueLabel} label={label} valueLabel={valueLabel} />
            )
          );
        })}
        {review.yield && <DetailItem label="収量" valueLabel={review.yield} />}
      </div>
      <div className="Body">
        {review.soilComponents.length === 0 ? null : (
          <div className="SoilComponent">
            <SoilComponentPieChart value={review.soilComponents} />
          </div>
        )}
        <p>{review.body}</p>
      </div>
      <div className="ImageContainer">
        {imageUrls.map(url => {
          return <Image key={url} src={url} />;
        })}
      </div>
      <div
        className={cn('GoodButtonContainer', { Active: isEvaluatedReview })}
        onClick={() => onGood(review.userId)}
      >
        <MdThumbUp className="Icon" />{' '}
        {review.good.count ? `${review.good.count}: ` : null} 参考になった！
      </div>
    </div>
  );
};
