import { SeedlingsId } from '@common/domain/entity/seedlings';
import { SpeciesId } from '@common/domain/valueObject/species';
import { FsManager } from '@fs/manager';
import { appConverters } from '@fs/converters/app';
import fs from 'firebase/firestore';

export type FsAppManager<T> = {
  path: string;
  converter: fs.FirestoreDataConverter<T>;
};
export namespace FsAppManager {
  export class User extends FsManager.User<typeof appConverters.user> {
    constructor() {
      super(appConverters.user);
    }
  }

  export class Species extends FsManager.Species<typeof appConverters.species> {
    constructor() {
      super(appConverters.species);
    }
  }

  export class Seedlings extends FsManager.Seedlings<typeof appConverters.seedlings> {
    constructor(speciesId: SpeciesId) {
      super(appConverters.seedlings, speciesId);
    }
  }

  export class Review extends FsManager.Review<typeof appConverters.review> {
    constructor(speciesId: SpeciesId, seedlingsId: SeedlingsId) {
      super(appConverters.review, speciesId, seedlingsId);
    }
  }
}
