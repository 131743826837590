import React from 'react';
import cn from 'classnames';
import { Control, useController } from 'react-hook-form';
import { Textarea } from '@chakra-ui/react';
import { MyAlert } from '@frontend/components/atoms/MyAlert';

export type ReviewCommentTextAreaProps = {
  className?: string;
  control: Control<any, any>;
};

const REVIEW_COMMENT_LIMIT = {
  MIN: 20,
  MAX: 9999,
};

export const ReviewCommentTextArea: React.FC<ReviewCommentTextAreaProps> = props => {
  const { className, control } = props;

  const controller = useController({
    control,
    name: 'reviewComment',
    defaultValue: undefined,
    rules: {
      required: '必須です。',
      maxLength: {
        value: REVIEW_COMMENT_LIMIT.MAX,
        message: `${REVIEW_COMMENT_LIMIT.MAX}文字までで入力してください。`,
      },
      // minLength: {
      //   value: REVIEW_COMMENT_LIMIT.MIN,
      //   message: `${REVIEW_COMMENT_LIMIT.MIN}文字以上入力してください。`,
      // },
    },
  });

  const errorMessage = (controller.formState.errors.reviewComment?.message ||
    '') as string;

  return (
    <div className={cn('ReviewCommentTextArea', className)}>
      <label htmlFor="reviewComment">レビュー内容</label>
      <Textarea className="ReviewComment" {...controller.field} fontSize={14} />
      <div className="Counter">
        {controller.field.value?.length || 0} / {REVIEW_COMMENT_LIMIT.MAX}
      </div>
      <MyAlert active={!!errorMessage} title={errorMessage} />
    </div>
  );
};
