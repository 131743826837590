import { FsAppManager } from '@fs/manager/app';
import { SpeciesId } from '@common/domain/valueObject/species';
import { SeedlingsId } from '@common/domain/entity/seedlings';
import { FSAppRepository } from '@frontend/repository/firestore/index';
import { UserId } from '@common/domain/entity/user';
import { UserAppRepository } from '@frontend/repository/firestore/user';
import { SeedlingsAppRepository } from '@frontend/repository/firestore/seedlings';
import { toggleArray } from '@common/support/array';
import { SpeciesAppRepository } from '@frontend/repository/firestore/species';
import { Review, ReviewId } from '@common/domain/entity/review';
import { StorageHooks } from '@frontend/hooks/storage';

export namespace ReviewAppRepository {
  type CreateInput = {
    review: Review;
    speciesId: SpeciesId;
    seedlingsId: SeedlingsId;
  };
  export const create = async (params: CreateInput) => {
    const { review, speciesId, seedlingsId } = params;
    // 1. save review
    const manager = new FsAppManager.Review(speciesId, seedlingsId);
    console.log({ path: manager.path });
    await FSAppRepository.addItemWithId(manager, review);
    console.log('saved a review: ', review);

    // 2. update review count of seedlings
    console.log('update review count');
    const count = await SeedlingsAppRepository.updateReviewCount(speciesId, seedlingsId);

    // 3. update review count of species
    console.log('update review count');
    await SpeciesAppRepository.updateReviewCount(speciesId, count);
  };

  type DeleteItemParams = {
    speciesId: SpeciesId;
    seedlingsId: SeedlingsId;
    reviewId: ReviewId;
  };

  export const deleteItem = async (params: DeleteItemParams) => {
    const { speciesId, seedlingsId, reviewId } = params;
    const manager = new FsAppManager.Review(speciesId, seedlingsId);
    const curReview = await FSAppRepository.getItem(manager, reviewId);
    if (!curReview) return;

    const review = await FSAppRepository.getItem(manager, reviewId);
    console.log('// 1. delete review');
    await FSAppRepository.deleteItem(manager, reviewId);

    console.log('// 2. remove good from users');
    await Promise.all(
      curReview.value.good.byUserIds.map(userId => {
        return UserAppRepository.updateGoodReviews(userId, reviewId, 'remove');
      }),
    );

    console.log('// 3. update review count of seedlings');
    const count = await SeedlingsAppRepository.updateReviewCount(speciesId, seedlingsId);

    console.log('// 4. update review count of species');
    await SpeciesAppRepository.updateReviewCount(speciesId, count);

    return { deletedReview: review };
  };

  type GoodParams = {
    speciesId: SpeciesId;
    seedlingsId: SeedlingsId;
    reviewId: ReviewId;
    byUserId: UserId;
  };
  type GoodResponse =
    | 'error'
    | {
        type: 'add' | 'remove';
        newGood: Review['good'];
      };
  export const good = async (params: GoodParams): Promise<GoodResponse> => {
    const { speciesId, seedlingsId, reviewId, byUserId } = params;

    const manager = new FsAppManager.Review(speciesId, seedlingsId);
    const curReview = (await FSAppRepository.getItem(manager, reviewId)).value;

    if (!curReview) return 'error';

    try {
      const { type, items: newByUserIds } = toggleArray(
        curReview.good.byUserIds,
        byUserId,
      );
      const newGood: Review['good'] = {
        count: curReview.good.count + (type === 'add' ? 1 : -1),
        byUserIds: newByUserIds,
      };
      await FSAppRepository.update(manager, reviewId, {
        good: newGood,
      });
      await UserAppRepository.updateGoodReviews(byUserId, reviewId, type);
      return {
        type: type,
        newGood: newGood,
      };
    } catch (e: any) {
      console.error(e);
      return 'error';
    }
  };
}
