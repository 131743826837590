export const imageRefs = {
  ImageGarage: {
    referenceName: 'IMAGE GARAGE',
    referenceUrl: 'https://img-garage.com/14365/',
  },
  Forest17: {
    referenceName: '無料写真素材「花ざかりの森」',
    referenceUrl: 'https://forest17.com/',
  },
  FreeSozaiTown: {
    referenceName: 'フリー素材タウン',
    referenceUrl: 'http://sozai.yutorilife.com/',
  },
} as const;
