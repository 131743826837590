import React from 'react';
import { INQUIRY_FORM_URL } from '@frontend/settings';

export type MyFooterProps = {};

export const MyFooter: React.FC<MyFooterProps> = props => {
  return (
    <div className="MyFooter">
      <span>タネログ by hedrall</span>
      <span>・</span>
      <a href={INQUIRY_FORM_URL} target="_blank" rel="noreferrer">
        問い合わせ
      </a>
    </div>
  );
};
