import React from 'react';
import cn from 'classnames';
import { Pie, Doughnut } from 'react-chartjs-2';

const COLORS = [
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba',
];
const getColor = (_: any, index: number) => {
  return COLORS[index % COLORS.length];
};

type DataItem = { label: string; value: number };
export type MyPieChartProps = {
  className?: string;
  data: DataItem[];
  title?: string;
};

type Data = Parameters<typeof Doughnut>[number]['data'];

export const MyPieChart: React.FC<MyPieChartProps> = props => {
  const { className, data, title } = props;

  const chartData: Data = {
    labels: data.map(i => i.label + ': ' + i.value),
    datasets: [
      {
        data: data.map(i => i.value),
        backgroundColor: data.map(getColor),
        // hoverOffset: 4,
      },
    ],
  };

  return (
    <div className={cn('MyPieChart', className)}>
      <Doughnut
        data={chartData}
        options={{
          layout: {
            padding: {
              left: 10,
              right: 10,
              bottom: 0,
              top: 0,
            },
          },
          plugins: {
            title: {
              display: !!title,
              text: title,
              padding: {
                top: 80,
                bottom: 0,
              },
            },
            tooltip: {
              enabled: false,
            },
            legend: {
              position: 'right',
            },
          },
        }}
      />
    </div>
  );
};
