import { ImageAttrs } from '@common/domain/valueObject/imageAttrs';
import { imageRefs } from '@frontend/components/pages/Admin/defs/imageRefs';

export const speciesDefs: {
  speciesId: string;
  image: ImageAttrs;
}[] = [
  {
    speciesId: 'fruits-リンゴ',
    image: {
      imageUrl: '/images/forest17.com-apple.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'fruits-ブドウ',
    image: {
      imageUrl: '/images/unsplash/grape.jpg',
    },
  },
  {
    speciesId: 'fruits-モモ',
    image: {
      imageUrl: '/images/unsplash/peach.jpg',
    },
  },
  {
    speciesId: 'fruits-カンキツ',
    image: {
      imageUrl: '/images/unsplash/citrus.jpg',
    },
  },
  {
    speciesId: 'fruits-ウンシュウミカン',
    image: {
      imageUrl: '/images/yunphoto.net.japanese-orange.jpeg',
    },
  },
  {
    speciesId: 'fruits-ニホンナシ',
    image: {
      imageUrl: '/images/publicdomainq.pear.jpeg',
    },
  },
  {
    speciesId: 'fruits-カキノキ',
    image: {
      imageUrl: '/images/img-garage-persimmon.jpeg',
      ...imageRefs.ImageGarage,
    },
  },
  {
    speciesId: 'fruits-スノキ（ブルーベリー）',
    image: {
      imageUrl: '/images/forest17.com-blue-berry.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'fruits-オウトウ（甘果）',
    image: {
      imageUrl: '/images/forest17.com-cherry.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'fruits-ウメ',
    image: {
      imageUrl: '/images/forest17.com-plum.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'fruits-マタタビ',
    image: {
      imageUrl: '/images/freeimages.com-kiwi.jpeg',
    },
  },
  {
    speciesId: 'fruits-ニホンスモモ',
    image: {
      imageUrl: '/images/unsplash/fruits.jpg',
    },
  },
  {
    speciesId: 'fruits-クリ',
    image: {
      imageUrl: '/images/forest17.com-chestnut.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'fruits-セイヨウナシ',
    image: {
      imageUrl: '/images/photo-ac-pear.jpeg',
    },
  },
  {
    speciesId: 'fruits-ビワ',
    image: {
      imageUrl: '/images/gahag.net-biwa.jpeg',
    },
  },
  {
    speciesId: 'fruits-パインアップル',
    image: {
      imageUrl: '/images/unsplash.com-pineapple.jpeg',
    },
  },
  {
    speciesId: 'fruits-イチジク',
    image: {
      imageUrl: '/images/pixabay.com-figs.jpeg',
    },
  },
  {
    speciesId: 'fruits-パッションフルーツ',
    image: {
      imageUrl: '/images/unsplash.com-passion-fruit.jpeg',
    },
  },
  {
    speciesId: 'fruits-マンゴー',
    image: {
      imageUrl: '/images/unsplash.com-mango.jpeg',
    },
  },
  {
    speciesId: 'fruits-ポポー',
    image: {
      imageUrl: '/images/free-materials.com-pawpaw.jpeg',
    },
  },
  {
    speciesId: 'vegetables-キュウリ',
    image: {
      imageUrl: '/images/unsplash/cucumber.jpg',
    },
  },
  {
    speciesId: 'vegetables-トマト',
    image: {
      imageUrl: '/images/unsplash/tomato.jpg',
    },
  },
  {
    speciesId: 'vegetables-ダイコン',
    image: {
      imageUrl: '/images/forest17.com-white-radish.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'vegetables-レタス',
    image: {
      imageUrl: '/images/unsplash/lettuce.jpg',
    },
  },
  {
    speciesId: 'vegetables-メロン',
    image: {
      imageUrl: '/images/unsplash/melon.jpg',
    },
  },
  {
    speciesId: 'vegetables-イチゴ',
    image: {
      imageUrl: '/images/unsplash/strrowberry.jpg',
    },
  },
  {
    speciesId: 'vegetables-キャベツ',
    image: {
      imageUrl: '/images/unsplash/cabbage.jpg',
    },
  },
  {
    speciesId: 'vegetables-スイカ',
    image: {
      imageUrl: '/images/unsplash/water-melon.jpg',
    },
  },
  {
    speciesId: 'vegetables-トウガラシ',
    image: {
      imageUrl: '/images/unsplash/chili-pepper.jpg',
    },
  },
  {
    speciesId: 'vegetables-アブラナ（在来ナタネ）',
    image: {
      imageUrl: '/images/sozai.yutorilife.com-aburana.jpeg',
      ...imageRefs.FreeSozaiTown,
    },
  },
  {
    speciesId: 'vegetables-カボチャ',
    image: {
      imageUrl: '/images/unsplash-pumpkin.jpeg',
    },
  },
  {
    speciesId: 'vegetables-ホウレンソウ',
    image: {
      imageUrl: '/images/forest17.com-spinach.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'vegetables-ハクサイ',
    image: {
      imageUrl: '/images/sothei.net-chinese-cabbage.jpeg',
    },
  },
  {
    speciesId: 'vegetables-タマネギ',
    image: {
      imageUrl: '/images/forest17.com-onion.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'vegetables-ニンジン',
    image: {
      imageUrl: '/images/unsplash.com-carrot.jpeg',
    },
  },
  {
    speciesId: 'vegetables-ナス',
    image: {
      imageUrl: '/images/tabe-photo.com-eggplant.jpeg',
    },
  },
  {
    speciesId: 'vegetables-ダイズ',
    image: {
      imageUrl: '/images/tabe-photo.com-soy.jpeg',
      ...imageRefs.FreeSozaiTown,
    },
  },
  {
    speciesId: 'vegetables-ブロッコリー',
    image: {
      imageUrl: '/images/forest17.com-broccoli.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'vegetables-カブ',
    image: {
      imageUrl: '/images/forest17.com-turnip.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'vegetables-エンドウ',
    image: {
      imageUrl: '/images/pro-foto.jp-peas.jpeg',
    },
  },
  {
    speciesId: 'vegetables-インゲンマメ',
    image: {
      imageUrl: '/images/forest17.com-kidney-beans.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'vegetables-トウモロコシ',
    image: {
      imageUrl: '/images/forest17.com-corn.jpeg',
      ...imageRefs.Forest17,
    },
  },
  {
    speciesId: 'vegetables-カリフラワー',
    image: {
      imageUrl: '/images/photo-pot.com-cauliflower.jpeg',
    },
  },
];
