import React, { ReactNode } from 'react';
import cn from 'classnames';

export type MyChipProps = {
  className?: string;
  title: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
};

export const MyChip: React.FC<MyChipProps> = props => {
  const { className, title, isActive = true, onClick } = props;
  return (
    <div className={cn('MyChip', className, { Active: isActive })} onClick={onClick}>
      {title}
    </div>
  );
};
