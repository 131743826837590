import * as fs from 'firebase/firestore';
import { useQuery } from '@tanstack/react-query';
import { sleep } from '@common/support/functions';
import { useState } from 'react';
import { RefValue } from '@frontend/support/type';
import { Entity } from '@common/domain/entity/common';
import { FsAppManager } from '@fs/manager/app';
import { FSAppRepository } from '@frontend/repository/firestore';
import { ValueOf } from 'type-fest';

const limit = 12;
type UseReadMoreResultItems<T> = (RefValue<T>[] | undefined)[];
type UseReadMoreResult<T> = {
  lastDocSnapshot: fs.DocumentSnapshot<unknown> | undefined;
  items: UseReadMoreResultItems<T>;
  resetCount: number;
};
const DEFAULT_STATE = <T>(): UseReadMoreResult<T> => {
  return {
    lastDocSnapshot: undefined,
    items: [undefined],
    resetCount: 0,
  };
};
export type UseReadMoreResponse<T extends Entity> = {
  itemList: UseReadMoreResultItems<T>;
  readMore: () => void;
  resetPage: () => void;
  destroyPage: () => void;
  isFetching: boolean;
  noMorePages: boolean;
  patchData: (id: T['id'], key: keyof T, value: ValueOf<T>) => void;
};

export type UseReadMoreFetcher<T> = (params: {
  startAfter: fs.DocumentSnapshot<unknown> | undefined;
}) => Promise<{
  items: RefValue<T>[];
  lastDocSnapshot: fs.DocumentSnapshot<T> | undefined;
}>;

export const useReadMore = <T extends Entity>(
  keys: any[],
  fetcher: UseReadMoreFetcher<T>,
): UseReadMoreResponse<T> => {
  const [result, setResult] = useState<UseReadMoreResult<T>>(DEFAULT_STATE<T>());
  const [noMorePages, setNoMorePages] = useState(false);

  const destroyPage = () => {
    setResult(pre => {
      return {
        lastDocSnapshot: undefined,
        items: [],
        resetCount: pre.resetCount + 1,
      };
    });
  };

  const resetPage = () => {
    setResult(pre => {
      const newVar = {
        ...DEFAULT_STATE<T>(),
        resetCount: pre.resetCount + 1,
      };
      return newVar;
    });
  };

  const readMore = () => {
    if (isFetching || noMorePages) return;
    setResult(pre => ({
      lastDocSnapshot: pre.lastDocSnapshot,
      items: [...pre.items, undefined],
      resetCount: pre.resetCount,
    }));
  };

  const { isFetching } = useQuery(
    [keys, result.items.length, result.resetCount],
    async () => {
      return await fetcher({ startAfter: result.lastDocSnapshot });
    },
    {
      onError: e => {
        console.error(e);
      },
      onSuccess: res => {
        if (res.items.length < limit) setNoMorePages(true);
        setResult(pre => {
          const returnValue = {
            lastDocSnapshot: res.lastDocSnapshot,
            items: [...pre.items.slice(0, -1), res.items],
            resetCount: pre.resetCount,
          };
          return returnValue;
        });
      },
      retry: false,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  // update fetched objects
  const patchData = (id: T['id'], key: keyof T, value: ValueOf<T>) => {
    const targetReview = result.items
      .filter(Boolean)
      .flat()
      .map(i => i?.value)
      .find(i => i?.id === id);
    if (!targetReview) return;
    targetReview[key] = value;
    setResult(pre => ({ ...pre }));
  };

  return {
    itemList: result.items,
    readMore,
    resetPage,
    isFetching,
    noMorePages,
    destroyPage,
    patchData,
  };
};

export const useById = <T extends Entity>(
  manager: FsAppManager<T>,
  uniqueKey: string,
  id: T['id'],
) => {
  const key = [uniqueKey, id];

  return useQuery(
    key,
    async () => {
      // todo delete
      await sleep(500);

      return await FSAppRepository.getItem(manager, id);
    },
    {
      retry: false,
      onError: e => {
        console.log(e);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );
};
