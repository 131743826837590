import { FsAppManager } from '@fs/manager/app';
import { Species, SpeciesId } from '@common/domain/valueObject/species';
import { FSAppRepository } from '@frontend/repository/firestore';
import { useEffect, useState } from 'react';

export const useFs_Species_GetById = (id: SpeciesId): { species: Species | null } => {
  const [species, setSpecies] = useState<Species | null>(null);

  useEffect(() => {
    const manager = new FsAppManager.Species();
    FSAppRepository.getItem(manager, id).then(res => {
      setSpecies(res.value);
    });
  }, [id]);

  return { species };
};
