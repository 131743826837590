import { FsAppManager } from '@fs/manager/app';
import { User, UserId } from '@common/domain/entity/user';
import { FSAppRepository } from '@frontend/repository/firestore/index';
import { RefValue } from '@frontend/support/type';
import { ReviewAppRepository } from '@frontend/repository/firestore/review';
import { SpeciesId } from '@common/domain/valueObject/species';
import { SeedlingsId } from '@common/domain/entity/seedlings';

export namespace SpeciesAppRepository {
  export const updateReviewCount = async (
    speciesId: SpeciesId,
    count: number,
  ): Promise<void> => {
    const manager = new FsAppManager.Species();
    await FSAppRepository.update(manager, speciesId, {
      reviewCount: count,
    });
    console.log('update review count of species to ', count);
  };
}
