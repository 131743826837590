import { atom } from 'recoil';
import { User } from '@common/domain/entity/user';

type MasterState = {
  users: User[];
};

export const MASTER_STATE = atom<MasterState>({
  key: 'Master',
  default: { users: [] },
});
