import React, { useMemo } from 'react';
import { Icon } from '@chakra-ui/react';
import { MdTrendingUp } from 'react-icons/md';
import { PlantIcon } from '@frontend/components/atoms/MyIcons';

export type SectionDividerProps = {
  title?: React.ReactNode;
  type?: 'trend' | 'species';
};

const renderIcon = (iconType: SectionDividerProps['type']) => {
  switch (iconType) {
    case 'trend':
      return <Icon className="Icon" as={MdTrendingUp} />;
    case 'species':
      return <PlantIcon />;
    default:
      return null;
  }
};

export const SectionDivider: React.FC<SectionDividerProps> = ({ title, type }) => {
  const icon = useMemo(() => renderIcon(type), [type]);

  return (
    <div className="SectionDivider">
      {icon}
      <span className="Title">{title || ''}</span>
    </div>
  );
};
