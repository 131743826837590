import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  labeledEnumConstToItem,
  SegmentButton,
} from '@frontend/components/atoms/SegmentButton';
import { Control, useController, useForm } from 'react-hook-form';
import { MyAccordion } from '@frontend/components/atoms/MyAccordion';
import { MyButton } from '@frontend/components/atoms/MyButton';
import { SoilComponentsInput } from '@frontend/components/molecules/SoilComponentsInput';
import { ReviewCommentTextArea } from '@frontend/components/atoms/ReviewCommentTextArea';
import { useAuthState } from '@frontend/store/auth/action';
import { User } from '@common/domain/entity/user';
import { SeedlingsId } from '@common/domain/entity/seedlings';
import { MyInput } from '@frontend/components/atoms/MyInput';
import { MyAlert } from '@frontend/components/atoms/MyAlert';
import {
  AREA,
  AreaKey,
  METHOD,
  MethodKey,
  PLANTING_ENVIRONMENT,
  PLANTING_PLACE,
  PlantingEnvironmentKey,
  PlantingPlaceKey,
  PURPOSE,
  PurposeKey,
  SEASON,
  SeasonKey,
  SEEDLINGS_TYPE,
  SeedlingsTypeKey,
  SoilComponentItem,
} from '@common/domain/entity/review/consts';
import { Review } from '@common/domain/entity/review';
import { ReviewFileInput } from '@frontend/components/atoms/ReviewFileInput';
import { useDisclosure } from '@chakra-ui/react';

export type ReviewInputProps = {
  className?: string;
  onSubmit: (newReview: Review, file: File[]) => Promise<void>;
  seedlingsId: SeedlingsId;
};

type Inputs = {
  purpose: PurposeKey;
  area: AreaKey;
  season: SeasonKey;
  seedlingsType: SeedlingsTypeKey;
  plantingPlace: PlantingPlaceKey;
  plantingEnvironment: PlantingEnvironmentKey;
  method: MethodKey;
  yield: string;
  soilComponents: SoilComponentItem[];
  reviewComment: string;
  images: { file: File; dataUrl: string }[];
};
export type ReviewInputSchema = Inputs;

const SegmentFormDefs = {
  REQUIRED: [PURPOSE],
  OPTIONAL: [AREA, SEASON, SEEDLINGS_TYPE, PLANTING_PLACE, PLANTING_ENVIRONMENT, METHOD],
} as const;

const DEFAULT_VALUES: { [Key in keyof Inputs]: Inputs[Key] } = {
  purpose: PURPOSE.ITEMS[0].KEY,
  area: AREA.ITEMS[0].KEY,
  season: SEASON.ITEMS[0].KEY,
  seedlingsType: SEEDLINGS_TYPE.ITEMS[0].KEY,
  plantingPlace: PLANTING_PLACE.ITEMS[0].KEY,
  plantingEnvironment: PLANTING_ENVIRONMENT.ITEMS[0].KEY,
  method: METHOD.ITEMS[0].KEY,
  yield: '',
  soilComponents: [],
  reviewComment: '',
  images: [],
};

type YieldInputProps = {
  control: Control<any, any>;
};
const YieldInput: React.FC<YieldInputProps> = props => {
  const { control } = props;
  const controller = useController({
    name: 'yield',
    control,
    rules: {
      maxLength: {
        value: 100,
        message: '100文字以内で入力してください。',
      },
    },
  });

  const errorMessage = (controller.formState.errors.yield?.message || '') as string;
  return (
    <div className="YieldInputContainer">
      <label>収穫量</label>
      <MyInput
        controller={controller}
        variant={'outline'}
        placeholder={'例) 10 ~ 20個'}
      />
      <MyAlert active={!!errorMessage} title={errorMessage} />
    </div>
  );
};

export const ReviewInput: React.FC<ReviewInputProps> = props => {
  const { className, seedlingsId, onSubmit } = props;
  const { user } = useAuthState();
  const [isLoading, setIsLoading] = useState(false);

  const { control, formState, trigger, watch, getValues, reset } = useForm<Inputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const resetWithUser = (user: User) => {
    const newVar: Inputs = {
      ...DEFAULT_VALUES,
      purpose: user?.defaultPurpose,
      area: user?.defaultArea,
    };
    reset(newVar);
  };
  useEffect(() => {
    if (user && !formState.isDirty) {
      console.log('reset');
      setTimeout(resetWithUser.bind(null, user), 0);
    }
  }, [user?.id]);

  const submit = async () => {
    if (!user || !formState.isValid) return;
    await trigger();

    const values = getValues();

    const newReview = Review.createNewReview({
      seedlingsId,
      userId: user.id,
      body: values.reviewComment,
      purpose: values.purpose,
      area: values.area,
      season: values.season,
      seedlingsType: values.seedlingsType,
      plantingPlace: values.plantingPlace,
      plantingEnvironment: values.plantingEnvironment,
      method: values.method,
      yield: values.yield,
      soilComponents:
        values.plantingPlace === 'planter'
          ? values.soilComponents.map(i => new SoilComponentItem(i))
          : [],
    });
    await onSubmit(
      newReview,
      values.images.map(i => i.file),
    );
  };

  const plantingPlace = watch('plantingPlace');
  const soilComponents = watch('soilComponents');

  return (
    <form className={cn('ReviewInput', className)}>
      {SegmentFormDefs.REQUIRED.map(def => {
        return (
          <SegmentButton
            key={def.NAME}
            name={def.NAME}
            label={def.LABEL}
            options={def.ITEMS.map(labeledEnumConstToItem)}
            control={control}
          />
        );
      })}

      <MyAccordion>
        {SegmentFormDefs.OPTIONAL.map(def => {
          return (
            <SegmentButton
              key={def.NAME}
              name={def.NAME}
              label={def.LABEL}
              options={def.ITEMS.map(labeledEnumConstToItem)}
              control={control}
            />
          );
        })}

        {/* 収穫量 */}
        <YieldInput control={control} />

        <SoilComponentsInput
          className={cn({ Hidden: plantingPlace !== 'planter' })}
          control={control}
          value={soilComponents}
        />
      </MyAccordion>

      <ReviewCommentTextArea control={control} />

      <ReviewFileInput
        control={control}
        imageCountLimit={5}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />

      <div className="SubmitButtonContainer">
        <MyButton
          className="SubmitButton"
          title="投稿する"
          onClick={submit}
          active={formState.isValid}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};
