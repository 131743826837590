import React from 'react';
import cn from 'classnames';
import { Control, useController } from 'react-hook-form';
import {
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { MyNumberInput } from '@frontend/components/atoms/MyNumberInput';
import { getSoilComponentLabel } from '@common/domain/entity/review/consts';

export type SoilComponentsItemProps = {
  className?: string;
  control: Control<any, any>;
  index: number;
  onRemove: () => void;
};

export const SoilComponentsItem: React.FC<SoilComponentsItemProps> = props => {
  const { className, control, index, onRemove } = props;
  const name = useController({ control, name: `soilComponents.${index}.name` });

  return (
    <div className={cn('SoilComponentsItem', className)}>
      <Input
        className="Name"
        {...name.field}
        size="sm"
        value={getSoilComponentLabel(name.field.value)}
        readOnly={true}
      />

      <MyNumberInput
        control={control}
        name={`soilComponents.${index}.ratio`}
        inputProps={{ max: 100, min: 0, step: 1, width: 78 }}
      />

      <div className="Delete" onClick={onRemove}>
        <DeleteIcon />
      </div>
    </div>
  );
};
