import React, { ReactNode } from 'react';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { MyHeader } from '@frontend/components/organisms/MyHeader';
import { MyFooter } from '@frontend/components/organisms/MyFooter';
import { Helmet } from 'react-helmet';

export const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ChakraProvider>
      <MyHeader />
      <Box className="Layout Container">
        <Box className="Content">{children}</Box>
      </Box>
      <MyFooter />
    </ChakraProvider>
  );
};
